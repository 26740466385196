/* eslint-env browser */
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import omit from 'lodash/fp/omit';
import VisuallyHidden from '../VisuallyHidden';

class Announce extends Component {
  static propTypes = {
    children: PropTypes.string,
    getApplicationNode: PropTypes.func,
  };

  static defaultProps = {
    getApplicationNode: () => document.body,
  };

  constructor(props) {
    super(props);

    if (typeof window !== 'undefined') {
      this.root = this.props.getApplicationNode();
      this.el = document.createElement('div');
    }

    if (this.props.children) {
      this.startTimeout();
    }

    this.state = {
      renderChildren: this.props.children ? true : false,
      latch: false,
      children: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.children && props.children !== state.children) {
      return {
        children: props.children,
        latch: !state.latch,
        renderChildren: true,
      };
    }

    return null;
  }

  componentDidMount() {
    this.root.appendChild(this.el);
  }

  componentDidUpdate(prevProps) {
    if (this.props.children && this.props.children !== prevProps.children) {
      this.startTimeout();
    }
  }

  componentWillUnmount() {
    this.root.removeChild(this.el);
    clearTimeout(this.timeout);
  }

  startTimeout = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ renderChildren: false });
    }, 2000);
  };

  render() {
    if (typeof window === 'undefined') {
      return null;
    }

    const { renderChildren, latch, children } = this.state;
    const rest = omit(['children', 'getApplicationNode'], this.props);

    return createPortal(
      <>
        <VisuallyHidden component="div" aria-live="assertive" {...rest}>
          {latch && renderChildren && children}
        </VisuallyHidden>
        <VisuallyHidden component="div" aria-live="assertive" {...rest}>
          {!latch && renderChildren && children}
        </VisuallyHidden>
      </>,
      this.el
    );
  }
}

export default Announce;
