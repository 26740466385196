import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';

import { Grid, Row, Cell } from '../Grid';
import Paragraph from '../Paragraph';

import styles from './footer.css';

const Footer = ({
  children,
  component: Component = 'footer',
  marginBottom = 0,
  textMap = { footer: 'HSBC Group' },
}) => {
  const currentYear = new Date().getFullYear();
  return (
    <Component
      className={cn(styles.Footer, getMarginBottomClass(marginBottom))}
    >
      <Grid>
        <Row>
          {children || (
            <Cell offsetLarge={1} sizeLarge={10}>
              <Paragraph marginBottom={0}>
                &copy; {textMap.footer} {currentYear}
              </Paragraph>
            </Cell>
          )}
        </Row>
      </Grid>
    </Component>
  );
};

Footer.displayName = 'Footer';

Footer.propTypes = {
  children: PropTypes.node,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  component: PropTypes.string,
  textMap: PropTypes.shape({
    footer: PropTypes.node,
  }),
};

export { Footer };

export default Footer;
