/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';

export default {
  title: 'HSBC',
  viewBox: '0 0 92 46',
  svgContent: (
    <>
      <rect fill="#ffffff" x="23" y="0" width="46" height="46" />
      <polygon fill="#db0011" points="91.87 23 69 0 69 46" />
      <polygon fill="#db0011" points="46 23 69 0 23 0" />
      <polygon fill="#db0011" points="0 23 23 46 23 0" />
      <polygon fill="#db0011" points="46 23 23 46 69 46" />
    </>
  ),
};
