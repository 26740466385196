import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';

import styles from './panel.css';

const Panel = ({
  component: Component = 'div',
  type = 'default',
  marginBottom = 4,
  fullHeight,
  collapseOnSmallViewport = false,
  children,
  ...rest
}) => (
  <Component
    className={cn(
      styles.Panel,
      {
        [styles[type]]: type,
        [styles.fullHeight]: fullHeight,
        [styles.collapseOnSmallViewport]: collapseOnSmallViewport,
      },
      getMarginBottomClass(marginBottom)
    )}
    {...rest}
  >
    {children}
  </Component>
);

Panel.displayName = 'Panel';

Panel.propTypes = {
  component: PropTypes.string,
  type: PropTypes.oneOf([
    'default',
    'jade',
    'slate',
    'charcoal',
    'silver-border',
    'jade-border',
    'scarlet-border',
    'information',
    'error',
    'success',
    'warning',
  ]),
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  fullHeight: PropTypes.bool,
  collapseOnSmallViewport: PropTypes.bool,
  children: PropTypes.node,
};

export { Panel };

export default Panel;
