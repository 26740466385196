import React from 'react';
import Theme from '../../../utils/Theme';

const theme = Theme.select({
  blackbrand: 'blackbrand',
  redbrand: 'redbrand',
  mands: 'mands',
});

const SvgComponent = (props) => (
  <>
    {(theme === 'redbrand' || theme === 'mands') && (
      <svg viewBox="0 0 18 18" width="1em" height="1em" {...props}>
        <circle cx={9} cy={9} r={9} />
        <path
          fill="#fff"
          d="M7.216 13.553l-3.63-3.629L4.859 8.65l2.357 2.358 5.925-5.925 1.273 1.273z"
        />
      </svg>
    )}
    {theme === 'blackbrand' && (
      <svg
        viewBox="0 0 18 18"
        width="1em"
        height="1em"
        version="1.1"
        {...props}
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(-398.000000, -191.000000)" stroke="#008900">
            <g transform="translate(398.000000, 191.000000)">
              <circle cx={9} cy={9} r={8.5} />
              <polyline
                strokeLinecap="round"
                strokeLinejoin="round"
                points="4.5 9.5 7.5 12.5 14.5 5.5"
              />
            </g>
          </g>
        </g>
      </svg>
    )}
  </>
);

export default SvgComponent;
