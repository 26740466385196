import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import omit from 'lodash/fp/omit';
import { withScroll } from 'react-window-decorators';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';
import { LogoHsbcGlobal } from '../Logo';
import { Grid, Row, Cell } from '../Grid';

import styles from './masthead.css';

const Masthead = withScroll(
  ({
    children,
    component: Component = 'header',
    logoSlot,
    marginBottom = 5,
    scrollPositionY,
    sticky = false,
    ...rest
  }) => (
    <Component
      className={cn(
        { [styles['Masthead--sticky']]: sticky },
        getMarginBottomClass(marginBottom)
      )}
      {...omit(['scrollPosition', 'scrollPositionX'], rest)}
    >
      <div
        className={cn(styles.wrapper, {
          [styles['wrapper--sticky']]: sticky,
          [styles['wrapper--scrolled']]: sticky && scrollPositionY > 0,
        })}
      >
        <Grid>
          <Row>
            {children || (
              <Cell offsetLarge={1} size={12} sizeMedium={12} sizeLarge={10}>
                <div className={styles.container}>
                  {logoSlot || <LogoHsbcGlobal />}
                </div>
              </Cell>
            )}
          </Row>
        </Grid>
      </div>
    </Component>
  )
);

Masthead.displayName = 'Masthead';

Masthead.propTypes = {
  children: PropTypes.node,
  component: PropTypes.string,
  logoSlot: PropTypes.node,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  /** docgen-ignore */
  sticky: PropTypes.bool,
};

export { Masthead };

export default Masthead;
