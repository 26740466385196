import React from 'react';
import Theme from '../../../utils/Theme';

const theme = Theme.select({
  blackbrand: 'blackbrand',
  redbrand: 'redbrand',
  mands: 'mands',
});

const SvgComponent = (props) => (
  <>
    {(theme === 'redbrand' || theme === 'mands') && (
      <svg viewBox="0 0 18 18" width="1em" height="1em" {...props}>
        <circle cx={9} cy={9} r={9} />
        <circle cx={9} cy={13} r={1.2} fill="#000" />
        <path d="M8.1 3.8h1.8v6H8.1z" fill="#000" />
      </svg>
    )}

    {theme === 'blackbrand' && (
      <svg
        viewBox="0 0 18 18"
        width="1em"
        height="1em"
        version="1.1"
        {...props}
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(-398.000000, -396.000000)">
            <g transform="translate(398.000000, 396.000000)">
              <circle stroke="#C85000" cx={9} cy={9} r={8.5} />
              <circle fill="#C85000" cx={9} cy={13} r={1} />
              <line
                x1={9}
                y1={5}
                x2={9}
                y2={10}
                stroke="#C85000"
                strokeLinecap="round"
              />
            </g>
          </g>
        </g>
      </svg>
    )}
  </>
);

export default SvgComponent;
