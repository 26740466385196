/* eslint-env browser */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/fp/pick';
import omit from 'lodash/fp/omit';
import escapeRegExp from 'lodash/fp/escapeRegExp';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import { withContext, eventBus } from '@piggybank/core';
import { TextInput } from '../TextInput';
import { Consumer } from '../Field/context';
import {
  toFullFormat,
  toBareNumberFormat,
  getCursorPos,
  getFormattedLength,
  startsWithSeparator,
  removeForbiddenChars,
  formatMoney,
  hasCorrectLength,
} from './utils';

const isBackspaceKey = (key) => key === 'Backspace';
const isDeleteKey = (key) => key === 'Delete';

export class CurrencyInputInner extends Component {
  constructor(props) {
    super(props);
    this.prevValues = {};
  }

  handleCursor = (e) => {
    const el = e.target;
    const { selectionStart, selectionEnd } = el;
    const { symbol, value } = this.props;
    const prefixLength = symbol.length + 1;

    if (
      (!value.length && selectionStart !== selectionEnd) ||
      (selectionStart === selectionEnd && selectionStart < prefixLength)
    ) {
      el.setSelectionRange(prefixLength, prefixLength);
    }
  };

  handleOnBlur = (parentOnBlur) => (event) => {
    const { decimalSeparator } = this.props;
    if (decimalSeparator) {
      const targetValue = event.target.value;
      const full = formatMoney(targetValue, this.props);
      const { prevValue } = this.prevValues;
      const { decimalSeparator } = this.props;

      let finalValue = toBareNumberFormat(full, decimalSeparator);

      if (!hasCorrectLength(finalValue, this.props)) {
        finalValue = toBareNumberFormat(prevValue, decimalSeparator);
      }

      eventBus.dispatch({
        type: 'onChange',
        component: 'CurrencyInput',
        value: finalValue,
        name: this.props.name,
      });
      this.props.onChange({ value: finalValue, event });
    }

    if (parentOnBlur) {
      parentOnBlur(event);
    }
  };

  navigateCursor = (el, symbolLength, decimalSeparator) => (
    fullyFormattedValue,
    posInValue
  ) => {
    if (isNumber(posInValue)) {
      const cursorPos = getCursorPos(
        fullyFormattedValue,
        posInValue,
        symbolLength,
        decimalSeparator
      );
      requestAnimationFrame(() => {
        el.setSelectionRange(cursorPos, cursorPos);
      });
    }
  };

  getPosModifier = (value) => {
    const { decimalSeparator } = this.props;
    let modifier = 0;
    const bareValue = removeForbiddenChars(value, decimalSeparator);

    if (startsWithSeparator(decimalSeparator)(bareValue)) {
      modifier++;
    }
    return modifier;
  };

  handleChange = ({ value, event }) => {
    const { target: el } = event;
    const { prevValue, prevStartInValue, predictedCursorPos } = this.prevValues;
    const { decimalSeparator, symbol } = this.props;
    const shiftCursor = this.navigateCursor(
      el,
      symbol.length,
      decimalSeparator
    );
    let finalValue = toBareNumberFormat(value, decimalSeparator);

    if (hasCorrectLength(finalValue, this.props)) {
      const cursorShiftValue = predictedCursorPos + this.getPosModifier(value);
      const fullyFormatted = toFullFormat(finalValue, this.props);
      shiftCursor(fullyFormatted, cursorShiftValue);
    } else {
      finalValue = toBareNumberFormat(prevValue, decimalSeparator);
      const fullyFormatted = toFullFormat(finalValue, this.props);
      shiftCursor(fullyFormatted, prevStartInValue);
    }

    eventBus.dispatch({
      type: 'onChange',
      component: 'CurrencyInput',
      value: finalValue,
      name: this.props.name,
    });
    this.props.onChange({ value: finalValue, event });
  };

  handleKeyDown = (e) => {
    const { target: el, key } = e;
    const { selectionStart, selectionEnd, value } = el;
    const { decimalSeparator, thousandSeparator } = this.props;
    const isNonRemovableChar = (char) => char === thousandSeparator;

    const startInValue = toBareNumberFormat(
      value.slice(0, selectionStart),
      decimalSeparator
    ).length;
    let predictedCursorPos = startInValue;

    if (isBackspaceKey(key)) {
      if (selectionStart === selectionEnd) {
        if (isNonRemovableChar(value.charAt(selectionStart - 1))) {
          el.setSelectionRange(selectionStart - 1, selectionStart - 1);
        }
        predictedCursorPos = startInValue - 1;
      }
    } else if (isDeleteKey(key)) {
      if (isNonRemovableChar(value.charAt(selectionStart))) {
        el.setSelectionRange(selectionStart, selectionStart);
      }
    } else {
      predictedCursorPos = startInValue + 1;
    }

    this.prevValues = {
      prevValue: value,
      predictedCursorPos,
      prevStartInValue: startInValue,
    };
  };

  render() {
    const {
      value,
      symbol,
      thousandSeparator,
      decimalSeparator,
      maxLength,
      forwardedRef,
      precision,
      ariaLabel,
      ...rest
    } = omit(['onChange'], this.props);

    const formattedMaxLength = getFormattedLength(maxLength, symbol, precision);

    return (
      <TextInput
        ariaLabel={ariaLabel}
        maxLength={formattedMaxLength}
        onChange={this.handleChange}
        onClick={this.handleCursor}
        onKeyDown={this.handleKeyDown}
        onKeyUp={this.handleCursor}
        pattern={`[\\d${escapeRegExp(
          `${symbol}${thousandSeparator}${
            decimalSeparator ? decimalSeparator : ''
          }`
        )} ]*`}
        ref={forwardedRef}
        type="tel"
        value={toFullFormat(value, this.props)}
        {...rest}
        onBlur={this.handleOnBlur(rest.onBlur)}
      />
    );
  }
}

const currencyInputPropTypes = {
  ...TextInput.propTypes,
  ariaLabel: PropTypes.string,
  symbol: PropTypes.string.isRequired,
  thousandSeparator: (props, propName, componentName) => {
    if (!(isString(props[propName]) && props[propName].length === 1)) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. Expected a single character.`
      );
    }
    if (props[propName] === props.decimalSeparator) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. DecimalSeparator should be different from thousandSeparator.`
      );
    }
  },
  decimalSeparator: PropTypes.oneOf(['.', ',']),
  precision: (props, propName, componentName) => {
    if (!isNumber(props[propName]) || props[propName] < 0) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. Number expected.`
      );
    }
    if (!props.decimalSeparator && props[propName] !== 0) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. ${propName} prop should be 0 when decimalSeparator is not provided`
      );
    }
    if (props.decimalSeparator && props[propName] === 0) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. ${propName} prop should NOT be 0 when decimalSeparator is provided`
      );
    }
  },
};

const currencyInputDefaultProps = {
  ariaLabel: null,
  thousandSeparator: ',',
  decimalSeparator: null,
  maxLength: 99,
  precision: 0,
  value: '',
};

CurrencyInputInner.displayName = 'CurrencyInputInner';
CurrencyInputInner.propTypes = currencyInputPropTypes;
CurrencyInputInner.defaultProps = currencyInputDefaultProps;

const CurrencyInput = React.forwardRef((props, ref) => (
  <CurrencyInputInner {...props} forwardedRef={ref} />
));
CurrencyInput.displayName = 'CurrencyInput';
CurrencyInput.propTypes = currencyInputPropTypes;
CurrencyInput.defaultProps = currencyInputDefaultProps;

export { CurrencyInput };

export default withContext(
  Consumer,
  pick([
    'name',
    'value',
    'onChange',
    'onBlur',
    'invalid',
    'required',
    'describers',
  ])
)(CurrencyInput);
