import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isString from 'lodash/fp/isString';
import kebabCase from 'lodash/fp/kebabCase';
import flowRight from 'lodash/fp/flowRight';
import omit from 'lodash/fp/omit';
import {
  getMarginBottomClass,
  marginBottomLevels,
  withContext,
} from '@piggybank/core';
import styles from './hint.css';

import {
  Consumer as FieldContext,
  registerAsDescriber,
} from '../Field/context';
import { Consumer as LabelContext } from '../Label/context';

const getUniqueIdFromText = (children) =>
  kebabCase(
    React.Children.toArray(children).filter(isString).join('').substr(0, 15)
  );

const Hint = ({ name, children, marginBottom = 2, ...rest }) => {
  return (
    <p
      className={cn(styles['Hint'], getMarginBottomClass(marginBottom))}
      id={
        name ? `${name}-hint-${getUniqueIdFromText(children)}` : rest.id || null
      }
      {...omit(['inLabel'], rest)}
    >
      {children}
    </p>
  );
};

Hint.displayName = 'Hint';

Hint.propTypes = {
  /** docgen-from-context:<Field/> */
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

export { Hint };

export default flowRight(
  withContext(LabelContext, ({ inLabel }) => ({ inLabel })),
  withContext(FieldContext, ({ name }) => ({ name })),
  registerAsDescriber(({ name, children, inLabel }) => {
    return inLabel
      ? undefined
      : `${name}-hint-${getUniqueIdFromText(children)}`;
  })
)(Hint);
