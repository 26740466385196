import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { getMarginBottomClass, marginBottomLevels } from '@piggybank/core';

import styles from './legend.css';

const Legend = ({ children, marginBottom = 2, ...rest }) => {
  return (
    <legend
      className={cn(styles['Legend'], getMarginBottomClass(marginBottom))}
      {...rest}
    >
      {children}
    </legend>
  );
};

Legend.propTypes = {
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  children: PropTypes.node,
};

export default Legend;
