import React from 'react';
import Theme from '../../../utils/Theme';

const theme = Theme.select({
  blackbrand: 'blackbrand',
  redbrand: 'redbrand',
  mands: 'mands',
});

const SvgComponent = (props) => (
  <>
    {(theme === 'redbrand' || theme === 'mands') && (
      <svg viewBox="0 0 18 18" width="1em" height="1em" {...props}>
        <path d="M8.309 1.682L.109 15.8A.8.8 0 0 0 .8 17h16.4a.8.8 0 0 0 .691-1.2l-8.2-14.118a.799.799 0 0 0-1.382 0z" />
        <g fill="#fff">
          <path d="M8.1 5h1.8v6H8.1z" />
          <circle cx={9} cy={13.576} r={1.25} />
        </g>
      </svg>
    )}

    {theme === 'blackbrand' && (
      <svg
        viewBox="0 0 18 18"
        width="1em"
        height="1em"
        version="1.1"
        {...props}
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(-398.000000, -293.000000)">
            <g transform="translate(398.000000, 293.000000)">
              <polygon
                stroke="#DC0101"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="9 0.5 0.5 17.5 17.5 17.5"
              />
              <circle fill="#DC0101" cx={9} cy={14} r={1} />
              <line
                x1={9}
                y1={6}
                x2={9}
                y2={11}
                stroke="#DC0101"
                strokeLinecap="round"
              />
            </g>
          </g>
        </g>
      </svg>
    )}
  </>
);

export default SvgComponent;
