/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import detectBrowser from '../../utils/detectBrowser';

import Masthead from '../Masthead';
import Footer from '../Footer';

import styles from './page.css';

// No support for <main> element on IE11
const componentDefault = detectBrowser.ie11 ? 'div' : 'main';

const Page = ({
  component: Component = componentDefault,
  children,
  headerSlot,
  footerSlot,
}) => (
  <div className={styles.Page}>
    <div className={styles.wrapper}>
      {headerSlot || <Masthead />}
      <Component className={styles.main} role="main">
        {children}
      </Component>
      {footerSlot || <Footer />}
    </div>
  </div>
);

Page.displayName = 'Page';

Page.propTypes = {
  headerSlot: PropTypes.node,
  footerSlot: PropTypes.node,
  children: PropTypes.node,
  component: PropTypes.string,
};

export { Page };

export default Page;
