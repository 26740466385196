import styles from './styles.css';

export const marginBottomLevels = [0, 1, 2, 3, 4, 5, 6, 7, 8];

export const getMarginBottomClass = (marginBottom, breakpoint) =>
  marginBottomLevels.indexOf(marginBottom) !== -1
    ? styles[
        `mb${
          breakpoint ? breakpoint.charAt(0).toLowerCase() : ''
        }${marginBottom}`
      ]
    : null;
