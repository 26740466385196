import React from 'react';
import PropTypes from 'prop-types';
import isNumber from 'lodash/fp/isNumber';

import styles from './grid.css';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';

export const Grid = (props) => <div className={styles.Grid} {...props} />;

Grid.propTypes = {
  children: PropTypes.node,
};

const rowPropTypes = {
  children: PropTypes.node,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottomMedium: PropTypes.oneOf(marginBottomLevels),
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottomLarge: PropTypes.oneOf(marginBottomLevels),
};

export const Row = ({
  marginBottom,
  marginBottomMedium,
  marginBottomLarge,
  ...rest
}) => (
  <div
    className={cn(styles.Row, {
      [getMarginBottomClass(marginBottom)]: isNumber(marginBottom),
      [getMarginBottomClass(marginBottomMedium, 'medium')]: isNumber(
        marginBottomMedium
      ),
      [getMarginBottomClass(marginBottomLarge, 'large')]: isNumber(
        marginBottomLarge
      ),
    })}
    {...rest}
  />
);

Row.propTypes = { ...rowPropTypes };

export const Cell = ({
  offset,
  offsetMedium,
  offsetLarge,
  size = 12,
  sizeMedium,
  sizeLarge,
  marginBottom,
  marginBottomMedium,
  marginBottomLarge,
  widthAuto,
  ...rest
}) => {
  const classes = cn(styles.Cell, styles['size-' + size], {
    [styles.widthAuto]: !!widthAuto,
    [styles['sizeMedium-' + sizeMedium]]: !!sizeMedium,
    [styles['sizeLarge-' + sizeLarge]]: !!sizeLarge,
    [styles['offset-' + offset]]: !!offset,
    [styles['offsetMedium-' + offsetMedium]]: !!offsetMedium,
    [styles['offsetLarge-' + offsetLarge]]: !!offsetLarge,
    [getMarginBottomClass(marginBottom)]: isNumber(marginBottom),
    [getMarginBottomClass(marginBottomMedium, 'medium')]: isNumber(
      marginBottomMedium
    ),
    [getMarginBottomClass(marginBottomLarge, 'large')]: isNumber(
      marginBottomLarge
    ),
  });

  return <div className={classes} {...rest} />;
};

Cell.propTypes = {
  ...rowPropTypes,
  offset: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]),
  offsetLarge: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]),
  offsetMedium: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]),
  size: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sizeLarge: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sizeMedium: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  widthAuto: PropTypes.bool,
};
