/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { useField } from '.';
import isEqual from 'lodash/fp/isEqual';
import isEmpty from 'lodash/isEmpty';

export function useComparablePrevious(next, compare = (a, b) => a === b) {
  const previousRef = useRef();
  const previous = previousRef.current;

  const isEqual = compare(previous, next);

  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  return isEqual ? previous : next;
}

function Describer({
  mapPropsToRegister,
  propsToRegister,
  children,
  componentName,
}) {
  const fieldContext = useField();
  if (process.env.NODE_ENV !== 'production' && isEmpty(fieldContext)) {
    // eslint-disable-next-line no-console
    console.info(
      `You are using <${componentName}/> component outside of the <Field/>. This can potentially break the accessibility. Make sure you know what you are doing.`
    );
  }
  const { register, error, touched, invalid, required } = fieldContext;

  const registerProps = useComparablePrevious(
    mapPropsToRegister({
      error,
      touched,
      invalid,
      required,
      ...propsToRegister,
    }),
    isEqual
  );

  useEffect(() => {
    if (register) {
      return register(registerProps);
    }
  }, [registerProps, register]);

  return React.Children.only(children);
}

/**
 *
 * Any component wrapped with this HOC will call "register" method of <FieldProvider /> and register
 * itself as a describer.
 *
 * This is then passed through Field Context as "describers" props (used in "aria-describedby").
 *
 * This is a way of passing information from Children to the Parent and then back to a different Children.
 *
 */
const registerAsDescriber = (mapPropsToRegister) => (Component) => {
  const componentName = Component.displayName || Component.name || 'Component';
  const Wrapped = React.forwardRef((props, ref) => (
    <Describer
      mapPropsToRegister={mapPropsToRegister}
      propsToRegister={props}
      componentName={componentName}
    >
      <Component {...props} ref={ref} />
    </Describer>
  ));

  Wrapped.displayName = `RegisteredDescriber(${componentName})`;
  Wrapped.WrappedComponent = Component;

  return hoistStatics(Wrapped, Component, { propTypes: true });
};

export default registerAsDescriber;
