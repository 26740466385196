import React from 'react';
import PropTypes from 'prop-types';
import Field from '../Field';
import cn from 'classnames';
import { marginBottomLevels, getMarginBottomClass } from '@piggybank/core';

import styles from './fieldset.css';

const Fieldset = ({ name, marginBottom = 5, ...rest }) =>
  name ? (
    <Field
      name={name}
      marginBottom={marginBottom}
      {...rest}
      component="fieldset"
    />
  ) : (
    <fieldset
      className={cn(styles.Fieldset, getMarginBottomClass(marginBottom))}
      {...rest}
    />
  );

Fieldset.displayName = 'Fieldset';

Fieldset.propTypes = {
  debounceWait: PropTypes.number,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  name: PropTypes.string,
  /** docgen-from-context:<Form/> */
  value: PropTypes.any,
  /** docgen-from-context:<Form/> */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** docgen-from-context:<Form/> */
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  /** docgen-from-context:<Form/> */
  invalid: PropTypes.bool,
  /** docgen-from-context:<Form/> */
  onChange: PropTypes.func,
  /** docgen-from-context:<Form/> */
  onBlur: PropTypes.func,
  /** docgen-from-context:<Form/> */
  required: PropTypes.bool,
  children: PropTypes.node,
  extraDescriber: PropTypes.string,
  invalidateNestedFields: PropTypes.bool,
};

Fieldset.defaultProps = {
  marginBottom: 5,
};

export { Fieldset };

export default Fieldset;
