import escapeRegExp from 'lodash/fp/escapeRegExp';
import isEmpty from 'lodash/isEmpty';
import memoizeOne from 'memoize-one';

const leadingZeroesRegex = /^0+([1-9]+\d*)/;
const thousandPlacesRegex = /\B(?=(\d{3})+(?!\d))/g;
const onlyZerosRegex = /^0+$/;
const singleDigitRegex = /\d/;

const isDigit = (char) => singleDigitRegex.test(char);

const getStartsWithSeparatorRegex = memoizeOne(
  (decimalSeparator) => new RegExp(`^[${decimalSeparator}]`)
);

const getForbiddenCharsRegex = memoizeOne(
  (decimalSeparator) =>
    new RegExp(`[^\\d${escapeRegExp(decimalSeparator)}]`, 'g')
);

const getOnlyZerosBeforeSeparatorRegex = memoizeOne(
  (decimalSeparator) => new RegExp(`^0+${decimalSeparator}`)
);

export const startsWithSeparator = (decimalSeparator) => (value) =>
  getStartsWithSeparatorRegex(decimalSeparator).test(value);

export const hasDecimalSeparator = (value, decimalSeparator) =>
  value && value.includes(decimalSeparator);

export const hasCorrectLength = (
  value,
  { decimalSeparator, maxLength, precision }
) => {
  let integerPart = value;
  if (hasDecimalSeparator(value, decimalSeparator)) {
    const splitted = value.split(decimalSeparator);
    if (splitted.length > 2) {
      return false;
    }
    integerPart = splitted[0];
    let decimalPart = splitted[1];
    if (decimalPart && decimalPart.length > precision) {
      return false;
    }
  }
  return integerPart.length <= maxLength;
};

export const toFullFormat = (
  value,
  { decimalSeparator, thousandSeparator, symbol }
) => {
  const output = value.trim();

  if (hasDecimalSeparator(output, decimalSeparator)) {
    const [integerPart, decimalPart] = output.split(decimalSeparator);
    let valWithSeparators = integerPart.replace(
      thousandPlacesRegex,
      thousandSeparator
    );
    const decimalNumbers = decimalPart ? decimalPart : '';
    return `${symbol} ${valWithSeparators}${decimalSeparator}${decimalNumbers}`;
  }

  const formattedNumber = output.replace(
    thousandPlacesRegex,
    thousandSeparator
  );
  return `${symbol} ${formattedNumber}`;
};

export const removeForbiddenChars = (value, decimalSeparator) => {
  const forbiddenCharsPattern = getForbiddenCharsRegex(decimalSeparator);
  return value.replace(forbiddenCharsPattern, '');
};

export const toBareNumberFormat = (value, decimalSeparator) => {
  const startsPattern = getStartsWithSeparatorRegex(decimalSeparator);
  const zerosPattern = getOnlyZerosBeforeSeparatorRegex(decimalSeparator);

  return removeForbiddenChars(value, decimalSeparator)
    .replace(leadingZeroesRegex, '$1')
    .replace(onlyZerosRegex, '0')
    .replace(startsPattern, `0${decimalSeparator}`)
    .replace(zerosPattern, `0${decimalSeparator}`);
};

export const formatMoney = (
  value,
  { symbol, decimalSeparator, precision, thousandSeparator }
) => {
  let bareNumber = toBareNumberFormat(value, decimalSeparator).replace(
    decimalSeparator,
    '.'
  );
  if (isEmpty(bareNumber)) {
    bareNumber = 0;
  }
  const fixedValue = Number.parseFloat(bareNumber)
    .toFixed(precision)
    .replace('.', decimalSeparator);

  return toFullFormat(fixedValue, {
    decimalSeparator,
    thousandSeparator,
    symbol,
  });
};

const isCharAllowed = (decimalSeparator) => (char) => {
  const isNum = isDigit(char);
  const isSep = char === decimalSeparator;
  return isNum || isSep;
};

export const getCursorPos = (
  val,
  posInValue,
  symbolLength,
  decimalSeparator
) => {
  let i = 0;
  let cursorPos = 0;
  const len = val.length;
  const isAllowed = isCharAllowed(decimalSeparator);

  while (i < posInValue && cursorPos < len) {
    if (isAllowed(val.charAt(cursorPos))) {
      i++;
    }
    cursorPos++;
  }

  return Math.max(symbolLength + 1, cursorPos);
};

const getThousandSeparatorsCount = (integerPartLength) =>
  ((integerPartLength - 1) / 3) | 0;

export const getFormattedLength = (integerPartLength, symbol, precision) => {
  if (typeof integerPartLength !== 'number' || !integerPartLength) {
    return integerPartLength;
  }
  const blankSpaceAfterSymbolLength = 1;
  let decimalPartLength = 0;
  if (precision > 0) {
    const decimalSeparatorLength = 1;
    decimalPartLength = precision + decimalSeparatorLength;
  }
  const thousandSeparatorsCount = getThousandSeparatorsCount(integerPartLength);
  return (
    symbol.length +
    blankSpaceAfterSymbolLength +
    integerPartLength +
    thousandSeparatorsCount +
    decimalPartLength
  );
};
