import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';
import { Provider } from '../ListItem/context';

import styles from './ordered-list.css';

const OrderedList = ({
  children,
  continuous = false,
  marginBottom = 4,
  spacing = 2,
  columns,
  type = 'decimal',
  ...rest
}) => {
  const hasMoreThanTenListItems = React.Children.count(children) >= 10;
  return (
    <ol
      className={cn(
        styles.OrderedList,
        {
          [styles.continuous]: continuous,
          [styles.columns]: !!columns,
          [styles[`columns${columns}`]]: !!columns,
        },
        getMarginBottomClass(marginBottom)
      )}
      {...rest}
    >
      <Provider
        value={{
          type,
          spacing,
          parentElementType: 'ol',
          columns: !!columns,
          largeMinWidth: hasMoreThanTenListItems,
        }}
      >
        {children}
      </Provider>
    </ol>
  );
};

OrderedList.displayName = 'OrderedList';

OrderedList.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.oneOf([2, 3]),
  continuous: PropTypes.bool,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  /**
   * Spacing of the `<ListItem/>`'s (0, 1, 2, 3, 4, 5, 6, 7)
   */
  spacing: PropTypes.oneOf(marginBottomLevels),
  type: PropTypes.oneOf(['lower-alpha', 'lower-roman', 'decimal', 'denary']),
};

export { OrderedList };

export default OrderedList;
