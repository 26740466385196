import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';

const Section = ({ children, marginBottom = 6, ...rest }) => {
  return (
    <section
      className={cn('Section', getMarginBottomClass(marginBottom))}
      {...rest}
    >
      {children}
    </section>
  );
};

Section.displayName = 'Section';

Section.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

export default Section;
