import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTrackingContext } from './TrackingContext';
import hoistStatics from 'hoist-non-react-statics';

const Wrapp = ({ children, componentName, name, pageName }) => {
  const {
    setTrackingPageName,
    trackingPageName,
    isTrackingInUse,
    track,
  } = useTrackingContext();

  const targetPageName = (isTrackingInUse && (pageName || name)) || undefined;

  useEffect(() => {
    if (targetPageName && trackingPageName !== targetPageName) {
      setTrackingPageName(targetPageName);
      track({
        type: 'mounted',
        component: componentName ? componentName : 'WizardPage',
        trackingPageName: targetPageName,
        name: targetPageName,
      });
    }
  }, [
    targetPageName,
    trackingPageName,
    componentName,
    setTrackingPageName,
    track,
  ]);

  if (targetPageName && trackingPageName !== targetPageName) {
    return null;
  }

  return children;
};

const withTrackPage = (pageName, componentName) => (Component) => {
  const Wrapped = React.forwardRef((props, ref) => (
    <Wrapp name={props.name} pageName={pageName} componentName={componentName}>
      <Component {...props} ref={ref} />
    </Wrapp>
  ));

  Wrapped.propTypes = {
    name: PropTypes.string,
  };

  Wrapped.displayName = `withTrackPage(${
    Component.displayName || Component.name || 'Component'
  })`;

  Wrapped.WrappedComponent = Component;

  return hoistStatics(Wrapped, Component, { propTypes: true });
};

export default withTrackPage;
