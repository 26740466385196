import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import eventBus from '../../utils/eventBus';
import styles from './button.css';
import ButtonLinkBase from '../ButtonLinkBase';

const Button = React.forwardRef(
  (
    {
      elementType = 'button',
      children,
      name,
      secondary,
      tertiary,
      onClick,
      ...rest
    },
    ref
  ) => {
    const primary = !secondary && !tertiary;
    return (
      <ButtonLinkBase
        className={cn(styles['Button'], {
          [styles['Button--primary']]: primary,
          [styles['Button--secondary']]: secondary,
          [styles['Button--tertiary']]: tertiary,
        })}
        elementType={elementType}
        primaryButton={primary}
        ref={ref}
        onClick={(e) => {
          const text = e.target.textContent || '';
          eventBus.dispatch({
            type: 'onClick',
            component: 'Button',
            text: text.trim(),
            name,
          });
          onClick && onClick(e);
        }}
        {...rest}
      >
        {children}
      </ButtonLinkBase>
    );
  }
);

Button.displayName = 'Button';

Button.propTypes = {
  elementType: PropTypes.oneOf(['button', 'a']),
  component: PropTypes.func,
  children: PropTypes.node,
  name: PropTypes.string,
  /**
   * proxied to eventBus
   */
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  /**
   * To be used in conjunction with `elementType = 'a'`
   */
  external: PropTypes.bool,
  /**
   * To be used in conjunction with `elementType = 'a'`
   */
  textMap: PropTypes.shape({
    newWindow: PropTypes.string,
  }),
  iconSlotLeft: PropTypes.node,
  iconSlotRight: PropTypes.node,
};

Button.defaultProps = {
  elementType: 'button',
  secondary: false,
  tertiary: false,
  textMap: {
    newWindow: 'Opens in a new window',
  },
};

export default Button;
