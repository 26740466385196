import isEmpty from 'lodash/fp/isEmpty';
import reduce from 'lodash/fp/reduce';
import includes from 'lodash/fp/includes';
import entries from 'lodash/fp/entries';
import find from 'lodash/fp/find';
import {
  getCountryCallingCode,
  parsePhoneNumberFromString,
  getCountries,
} from 'libphonenumber-js/core';

export const getCountryCodes = (metadata) =>
  reduce(
    (countriesArr, [, shortName]) => {
      // Only use country codes supported by libphonenumber and i18n-iso-countries
      // In this list are regions which get grouped into GB, and a special 001 number
      if (!includes(shortName, ['AC', 'TA', '001'])) {
        return [
          ...countriesArr,
          {
            countryCode: getCountryCallingCode(shortName, metadata),
            shortName,
          },
        ];
      }

      return countriesArr;
    },
    [],
    entries(getCountries(metadata))
  );

export const getFormattedValues = (
  value,
  countryCode,
  extendedValues,
  metadata
) => {
  // Outputs a value object in the format:
  //
  // {
  //    raw: '' // User's input
  //    value: '', // Output in E.164 format e.g. +442073334444 OR "invalid" OR "incomplete"
  //    countryCode: '' // User's country code selection
  //    extendedValues: { ... } // extended phone data from libphonenumber-js
  // }

  const phoneNumber = parsePhoneNumberFromString(value, countryCode, metadata);

  let formattedValues = { countryCode, raw: value };

  if (isEmpty(phoneNumber)) {
    formattedValues.value = 'incomplete';
  } else {
    const phoneNumberIsValid = phoneNumber.isValid();

    formattedValues.value = !phoneNumberIsValid
      ? 'invalid'
      : phoneNumber.format('E.164');

    if (extendedValues) {
      formattedValues.extendedValues = {
        carrierCode: phoneNumber.carrierCode,
        country: phoneNumber.country,
        countryCallingCode: phoneNumber.countryCallingCode,
        ext: phoneNumber.ext,
        phone: phoneNumber.nationalNumber,
        possible: phoneNumber.isPossible(),
        valid: phoneNumberIsValid,
      };
    }
  }

  return formattedValues;
};

export const checkPartialNumberChangesCountry = (value, state, metadata) => {
  // When we can't parse the number (it might be too short/invalid)
  // If field starts with +d, +dd or +ddd then try to convert this to a country code
  // and see if it's different to the currently selected one
  let countryMatch;

  const matchCountryCallingCode = value.match(/^\+(\d{0,3})/);

  if (matchCountryCallingCode) {
    const countryCode = matchCountryCallingCode[1];

    for (var i = 3; i > 0; i--) {
      const countryCodeSubstring = countryCode.substring(0, i);

      // if the country code is different to the current country code
      if (
        countryCodeSubstring !==
        getCountryCallingCode(state.countryCode, metadata)
      ) {
        const countryCodes = getCountryCodes(metadata);
        countryMatch = find(
          {
            countryCode: countryCodeSubstring,
          },
          countryCodes
        );

        // if there’s a matching country in our list and it's different to the state countryCode
        if (countryMatch && countryMatch.shortName !== state.countryCode) {
          // Set all incomplete North American numbers to US (unless we're already on a North American number)
          if (
            countryMatch.countryCode === '1' &&
            getCountryCallingCode(state.countryCode, metadata) !== '1'
          ) {
            return {
              countryCode: countryMatch.countryCode,
              shortName: 'US',
            };
          }

          // Otherwise return the new country
          return countryMatch;
        }
      }
    }
  }

  return false;
};
