import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import pick from 'lodash/fp/pick';
import {
  getMarginBottomClass,
  marginBottomLevels,
  withContext,
  eventBus,
} from '@piggybank/core';
import { NumberInput } from '../NumberInput';
import isValidInteger from '../../utils/isValidInteger';
import isBlurringOutsideRefs from '../../utils/isBlurringOutsideRefs';
import { Consumer } from '../Field/context';
import leftPad from 'left-pad';
import omit from 'lodash/fp/omit';

import styles from './date-input.css';

export const FORMAT_YYYY = 'YYYY';
export const FORMAT_YYYY_REGEX = /(\d{4})/;
export const FORMAT_YYYYMM = 'YYYY-MM';
export const FORMAT_YYYYMM_REGEX = /(\d{4})-(\d{2})/;
export const FORMAT_YYMM = 'YY-MM';
export const FORMAT_YYMM_REGEX = /(\d{2})-(\d{2})/;
export const FORMAT_YYYYMMDD = 'YYYY-MM-DD';
export const FORMAT_YYYYMMDD_REGEX = /(\d{4})-(\d{2})-(\d{2})/;
export const KEYCODE_LEFT_ARROW = 37;
export const KEYCODE_RIGHT_ARROW = 39;
export const KEYCODE_BACKSPACE = 8;

export const yearRangeMax = new Date().getFullYear() - 1950;

const EMPTY_DATE = { day: '', month: '', year: '' };

export const maskAddByFormat = {
  [FORMAT_YYYY]: (date) => {
    if (!date.year) return '';
    if (date.year.length < 4) return 'incomplete';
    return date.year;
  },
  [FORMAT_YYYYMM]: (date) => {
    if (!(date.year || date.month)) return '';
    if (date.year.length < 4 || date.month.length < 1) return 'incomplete';
    if (date.month < 1 || date.month > 12) return 'invalid';
    return `${date.year}-${leftPad(date.month, 2, 0)}`;
  },
  [FORMAT_YYMM]: (date) => {
    if (!(date.year || date.month)) return '';
    if (date.year.length < 2 || date.month.length < 1) return 'incomplete';
    if (date.month < 1 || date.month > 12) return 'invalid';
    return `${+date.year > yearRangeMax ? '19' : '20'}${date.year}-${leftPad(
      date.month,
      2,
      0
    )}`;
  },
  [FORMAT_YYYYMMDD]: (date) => {
    if (!(date.year || date.month || date.day)) return '';
    if (date.year.length < 4 || date.month.length < 1 || date.day.length < 1) {
      return 'incomplete';
    }

    const day = Number(date.day);
    const month = Number(date.month);
    const year = Number(date.year);

    const dateValidation = new Date();
    dateValidation.setFullYear(year, month - 1, day);

    if (
      dateValidation.getFullYear() != year ||
      dateValidation.getMonth() != month - 1 ||
      dateValidation.getDate() != day
    ) {
      return 'invalid';
    }

    return `${date.year}-${leftPad(date.month, 2, 0)}-${leftPad(
      date.day,
      2,
      0
    )}`;
  },
};

export const maskRemoveByFormat = {
  [FORMAT_YYYY]: (date) => {
    if (!FORMAT_YYYY_REGEX.test(date)) return null;
    return { year: date, month: '', day: '' };
  },
  [FORMAT_YYYYMM]: (date) => {
    const result = FORMAT_YYYYMM_REGEX.exec(date);
    if (!result) return null;
    const [, year, month] = result;
    return { year, month, day: '' };
  },
  [FORMAT_YYMM]: (date) => {
    const result = FORMAT_YYMM_REGEX.exec(date);
    if (!result) return null;
    const [, year, month] = result;
    return { year, month, day: '' };
  },
  [FORMAT_YYYYMMDD]: (date) => {
    const result = FORMAT_YYYYMMDD_REGEX.exec(date);
    if (!result) return null;
    const [, year, month, day] = result;
    return { year, month, day };
  },
};

class DateInput extends Component {
  static propTypes = {
    format: PropTypes.oneOf([
      FORMAT_YYYY,
      FORMAT_YYYYMM,
      FORMAT_YYMM,
      FORMAT_YYYYMMDD,
    ]),
    textMap: PropTypes.shape({
      day: PropTypes.node,
      dayAriaLabel: PropTypes.string,
      month: PropTypes.node,
      monthAriaLabel: PropTypes.string,
      year: PropTypes.node,
      yearAriaLabel: PropTypes.string,
    }),
    /** docgen-from-context:<Fieldset/> */
    name: PropTypes.string,
    /** docgen-from-context:<Fieldset/> */
    onBlur: PropTypes.func,
    /**
     * docgen-from-context:<Fieldset/>
     * ---
     * proxied to eventBus
     */
    onChange: PropTypes.func.isRequired,
    /** docgen-from-context:<Fieldset/> */
    value: PropTypes.string,
    /** docgen-from-context:<Fieldset/> */
    invalid: PropTypes.bool,
    /** docgen-from-context:<Fieldset/> */
    required: PropTypes.bool,
    /** docgen-from-context:<Fieldset/> */
    describers: PropTypes.string,
    disableAriaLabel: PropTypes.bool,
    /**
     * 0, 1, 2, 3, 4, 5, 6, 7
     */
    marginBottom: PropTypes.oneOf(marginBottomLevels),
  };

  static defaultProps = {
    value: '',
    format: FORMAT_YYYYMMDD,
    textMap: {
      day: 'Day',
      month: 'Month',
      year: 'Year',
    },
    invalid: false,
    required: true,
    marginBottom: 0,
    disableAriaLabel: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      date: EMPTY_DATE,
    };

    this.isShortYear = props.format === 'YY-MM';

    this.inputRefs = {
      day: React.createRef(),
      month: React.createRef(),
      year: React.createRef(),
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value === state.lastValue) {
      return null;
    }

    const unMaskedPropsValue = maskRemoveByFormat[props.format](props.value);
    const isPropsDateEmpty = props.value === '';

    const hasExactlyTheSameNumbers = (propsDate, stateDate) =>
      !propsDate ||
      (+propsDate.year === +stateDate.year &&
        +propsDate.month === +stateDate.month &&
        +propsDate.day === +stateDate.day);

    if (
      hasExactlyTheSameNumbers(unMaskedPropsValue, state.date) &&
      !isPropsDateEmpty
    ) {
      return { lastValue: props.value };
    }

    return {
      date: isPropsDateEmpty ? EMPTY_DATE : unMaskedPropsValue,
      lastValue: props.value,
    };
  }

  getAriaLabel = (unit, unitLabel) => {
    return unitLabel || `${unit} input`;
  };

  getDateByKey = (key) => {
    const date = this.state.date;
    return date[key];
  };

  handleChange = (inputValue, key, event) => {
    if (isValidInteger(inputValue)) {
      const date = { ...this.state.date, [key]: inputValue };
      const value = maskAddByFormat[this.props.format](date);
      this.setState({ date });
      eventBus.dispatch({
        type: 'onChange',
        component: 'DateInput',
        value,
        name: this.props.name,
      });
      this.props.onChange({
        value,
        event,
      });
    }
  };

  handleBlur = (e) => {
    if (this.props.onBlur) {
      isBlurringOutsideRefs(e, this.inputRefs).then((result) => {
        if (result) this.props.onBlur(e);
      });
    }
  };

  handleKeyDown(e, nextField, prevField) {
    if (nextField) {
      if (
        e.target.selectionStart === e.target.value.length &&
        e.keyCode === KEYCODE_RIGHT_ARROW
      ) {
        e.preventDefault();
        this.inputRefs[nextField].current.focus();
        this.inputRefs[nextField].current.setSelectionRange(0, 0);
      }
    }

    if (
      prevField &&
      e.target.selectionEnd === 0 &&
      (e.keyCode === KEYCODE_BACKSPACE || e.keyCode === KEYCODE_LEFT_ARROW)
    ) {
      e.preventDefault();
      const end = this.inputRefs[prevField].current.value.length;
      this.inputRefs[prevField].current.focus();
      this.inputRefs[prevField].current.setSelectionRange(end, end);
    }
  }

  handleKeyUp(e, nextField) {
    const maxLength = parseInt(e.target.getAttribute('maxLength'), 10);
    const isNumber =
      (e.keyCode >= 48 && e.keyCode <= 57) || // Number keys
      (e.keyCode >= 96 && e.keyCode <= 105); // Numpad number keys

    if (
      nextField &&
      !this.inputRefs[nextField].current.value.length &&
      e.target.value.length === maxLength &&
      isNumber
    ) {
      this.inputRefs[nextField].current.focus();
    }
  }

  render() {
    const {
      format,
      textMap,
      name,
      onBlur,
      invalid,
      required,
      describers,
      marginBottom,
      disableAriaLabel,
      ...rest
    } = omit(['onChange', 'value'], this.props);

    return (
      <div
        className={cn(styles.DateInput, getMarginBottomClass(marginBottom))}
        {...rest}
      >
        {![FORMAT_YYMM, FORMAT_YYYYMM, FORMAT_YYYY].includes(format) && (
          <div className={styles.day}>
            <label className={styles.label} htmlFor={`${name}-date-day-field`}>
              {textMap.day}
            </label>
            <NumberInput
              fullWidth
              marginBottom={0}
              maxLength={2}
              name={`${name}-date-day`}
              onBlur={onBlur ? this.handleBlur : null}
              onChange={({ value, event }) =>
                this.handleChange(value, 'day', event)
              }
              onKeyDown={(e) => this.handleKeyDown(e, 'month', null)}
              onKeyUp={(e) => this.handleKeyUp(e, 'month')}
              ref={this.inputRefs.day}
              value={this.getDateByKey('day')}
              invalid={invalid}
              required={required}
              describers={describers}
              disableAriaLabel={disableAriaLabel}
              ariaLabel={this.getAriaLabel(textMap.day, textMap.dayAriaLabel)}
            />
            <span aria-hidden="true" className={styles.separator}>
              /
            </span>
          </div>
        )}
        {format !== FORMAT_YYYY && (
          <div className={styles.month}>
            <label
              className={styles.label}
              htmlFor={`${name}-date-month-field`}
            >
              {textMap.month}
            </label>
            <NumberInput
              fullWidth
              marginBottom={0}
              maxLength={2}
              name={`${name}-date-month`}
              onBlur={this.handleBlur}
              onChange={({ value, event }) => {
                this.handleChange(value, 'month', event);
              }}
              onKeyDown={(e) =>
                this.handleKeyDown(
                  e,
                  'year',
                  format === FORMAT_YYYYMMDD && 'day'
                )
              }
              onKeyUp={(e) => this.handleKeyUp(e, 'year')}
              ref={this.inputRefs.month}
              value={this.getDateByKey('month')}
              invalid={invalid}
              required={required}
              describers={describers}
              disableAriaLabel={disableAriaLabel}
              ariaLabel={this.getAriaLabel(
                textMap.month,
                textMap.monthAriaLabel
              )}
            />
            <span aria-hidden="true" className={styles.separator}>
              /
            </span>
          </div>
        )}
        <div className={this.isShortYear ? styles.shortYear : styles.year}>
          <label className={styles.label} htmlFor={`${name}-date-year-field`}>
            {textMap.year}
          </label>
          <NumberInput
            fullWidth
            marginBottom={0}
            maxLength={this.isShortYear ? 2 : 4}
            name={`${name}-date-year`}
            onBlur={this.handleBlur}
            onChange={({ value, event }) =>
              this.handleChange(value, 'year', event)
            }
            onKeyDown={(e) =>
              this.handleKeyDown(e, null, format !== FORMAT_YYYY && 'month')
            }
            ref={this.inputRefs.year}
            value={this.getDateByKey('year')}
            invalid={invalid}
            required={required}
            describers={describers}
            disableAriaLabel={disableAriaLabel}
            ariaLabel={this.getAriaLabel(textMap.year, textMap.yearAriaLabel)}
          />
        </div>
      </div>
    );
  }
}

export { DateInput };

export default withContext(
  Consumer,
  pick([
    'name',
    'value',
    'onChange',
    'onBlur',
    'invalid',
    'required',
    'describers',
  ])
)(DateInput);
