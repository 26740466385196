/* eslint-disable react/display-name */
import React from 'react';

export default (props) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      d="M 0.53 9.00 L 5.89 14.33 L 17.25 2.93"
    />
  </svg>
);
