import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import pull from 'lodash/fp/pull';
import cn from 'classnames';
import { Checkbox } from '../Checkbox';
import { Radio } from '../Radio';
import Label from '../Label';
import pick from 'lodash/fp/pick';
import memoize from 'lodash/fp/memoize';
import {
  getMarginBottomClass,
  marginBottomLevels,
  withContext,
  eventBus,
} from '@piggybank/core';
import { Consumer } from '../Field/context';
import styles from './tile-group.css';

const TileInput = React.forwardRef(
  (
    {
      name,
      id,
      value,
      isCheckbox,
      selected,
      onChange,
      onBlur,
      invalid,
      ...rest
    },
    ref
  ) =>
    isCheckbox ? (
      <Checkbox
        id={id}
        name={value}
        checked={selected}
        onChange={onChange}
        onBlur={onBlur}
        invalid={invalid}
        ref={ref}
        {...rest}
      />
    ) : (
      <Radio
        id={id}
        name={name}
        value={value}
        selectedValue={selected ? value : ''}
        onChange={onChange}
        onBlur={onBlur}
        invalid={invalid}
        ref={ref}
        {...rest}
      />
    )
);

TileInput.displayName = 'TileInput';

TileInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isCheckbox: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

class TileGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { edgeMargin: null };

    this.getInputRefs = memoize((n) => {
      return [...new Array(n)].map(() => React.createRef());
    });

    this.getHandleChange = memoize((key) => ({ value: checked, event }) => {
      let value;

      if (!Array.isArray(this.props.value)) {
        value = this.isCheckbox && !checked ? '' : key;
      } else {
        if (this.isCheckbox) {
          if (checked) {
            value = [...this.props.value, key].sort((a, b) => {
              const itemA = this.keySortOrder[a];
              const itemB = this.keySortOrder[b];

              return itemA > itemB ? 1 : itemA < itemB ? -1 : 0;
            });
          } else {
            value = [...pull(key, this.props.value)];
          }
        } else {
          value = [key];
        }
      }

      this.props.onChange({ value, event });
      eventBus.dispatch({
        type: 'onChange',
        component: 'TileGroup',
        value,
        name: this.props.name,
      });
    });
  }

  cancelEdgeMargin = (margin) => {
    this.setState({ edgeMargin: margin });
  };

  inputRefs = [];
  keySortOrder = {};
  isCheckbox = false;

  handleBlur = (e) => {
    const newFocusedInput = this.inputRefs.filter(
      (ref) => ref.current === e.relatedTarget
    );
    if (this.props.onBlur && newFocusedInput.length === 0) {
      this.props.onBlur(e);
    }
  };

  renderTile = (child, index) => {
    const { name, value, invalid, describers } = this.props;

    const tileName = `${name}-${child.props.value}`;
    const inputId = `${tileName}-${
      this.isCheckbox ? 'checkbox' : 'radio-item'
    }`;

    const selected = Array.isArray(value)
      ? value.includes(child.props.value)
      : value === child.props.value;
    const handleChange = this.getHandleChange(child.props.value);

    const inputProps = {
      id: inputId,
      name,
      value: child.props.value,
      isCheckbox: this.isCheckbox,
      selected,
      onChange: handleChange,
      onBlur: this.handleBlur,
      invalid,
      describers,
      ref: this.inputRefs[index],
    };

    const labelProps = {
      name: tileName,
      htmlFor: inputId,
    };

    return React.cloneElement(child, {
      Input: TileInput,
      Label,
      inputProps,
      labelProps,
      invalid,
      selected,
      onChange: handleChange,
      cancelEdgeMargin: this.cancelEdgeMargin,
    });
  };

  render() {
    const { children, marginBottom, multiSelect } = this.props;
    const { edgeMargin } = this.state;

    this.isCheckbox = multiSelect || React.Children.count(children) === 1;

    // We want the output value to be sorted by the order of the tiles in the DOM so we'll keep track of this here
    React.Children.forEach(children, (child, index) => {
      this.keySortOrder[child.props.value] = index;
    });

    this.inputRefs = this.getInputRefs(children.length);
    const EdgeMarginRemover = edgeMargin ? 'div' : Fragment;
    const edgeRemoverProps = edgeMargin
      ? {
          className: styles.TileGroup,
          style: {
            marginBottom: `calc(-1 * var(--unit${edgeMargin}))`,
          },
        }
      : {};

    return (
      <div className={cn(styles.TileGroup, getMarginBottomClass(marginBottom))}>
        <EdgeMarginRemover {...edgeRemoverProps}>
          {React.Children.map(children, this.renderTile)}
        </EdgeMarginRemover>
      </div>
    );
  }
}

TileGroup.defaultProps = {
  multiSelect: false,
  marginBottom: 0,
};

TileGroup.displayName = 'TileGroup';

TileGroup.propTypes = {
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  /**
   * pay attention that Form's initialValue has to be an array
   */
  multiSelect: PropTypes.bool,
  /** docgen-from-context:<Fieldset/> */
  invalid: PropTypes.bool,
  /** docgen-from-context:<Fieldset/> */
  describers: PropTypes.string,
  /** docgen-from-context:<Fieldset/> */
  name: PropTypes.string.isRequired,
  /** docgen-from-context:<Fieldset/> */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]).isRequired,
  /**
   * docgen-from-context:<Fieldset/>
   * ---
   * proxied to eventBus
   */
  onChange: PropTypes.func,
  /** docgen-from-context:<Fieldset/> */
  onBlur: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export { TileGroup };

export default withContext(
  Consumer,
  pick(['name', 'value', 'onChange', 'onBlur', 'invalid', 'describers'])
)(TileGroup);
