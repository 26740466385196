import React, { Component } from 'react';
import PropTypes from 'prop-types';
import identity from 'lodash/fp/identity';
import debounce from 'lodash/debounce';
import omit from 'lodash/fp/omit';
import isEqual from 'lodash/fp/isEqual';
import isEmpty from 'lodash/fp/isEmpty';
import mapValues from 'lodash/fp/mapValues';
import { getIn } from 'formik';
import { withContext } from '@piggybank/core';
import { Consumer } from './context';

import FieldProvider from './FieldProvider';

class FormikAdapter extends Component {
  static propTypes = {
    debounceWait: PropTypes.number,
    invalidateNestedFields: PropTypes.bool,
    field: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
    }),
    form: PropTypes.shape({
      setFieldValue: PropTypes.func,
      setFieldTouched: PropTypes.func,
      setStatus: PropTypes.func,
      initialValues: PropTypes.object,
      touched: PropTypes.object,
      errors: PropTypes.object,
      status: PropTypes.object,
      submitCount: PropTypes.number,
    }),
    context: PropTypes.shape({
      name: PropTypes.string,
      onBlur: PropTypes.func,
      touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // If this field is already touched when it first renders,
    // 'untouch' it so that errors are hidden
    if (this.getTouched()) {
      this.props.form.setFieldTouched(this.props.field.name, false);
    }

    this.state = {
      value: props.field.value,
      lastFormikFieldValue: props.field.value,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEqual(props.field.value, state.lastFormikFieldValue)) {
      return {
        value: props.field.value,
        lastFormikFieldValue: props.field.value,
      };
    }

    return null;
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.setFieldValue.flush();
  }

  setValue = ({ value }) => {
    this.setState({ value });
    this.setFieldValue(this.props.field.name, value);
  };

  setFieldValue = debounce(
    (...args) => this.props.form.setFieldValue(...args),
    typeof this.props.debounceWait !== 'undefined'
      ? this.props.debounceWait
      : getIn(this.props, 'form.status.debounceWait'),
    { leading: true }
  );

  handleChange = (...args) => {
    if (this.props.onChange) {
      this.props.onChange(this.setValue, ...args);
    } else {
      this.setValue(...args);
    }
  };

  handleBlur = (e, { isGroup } = {}) => {
    if (this.props.context && this.props.context.onBlur) {
      this.props.context.onBlur(e, { isGroup: true });
    }

    if (!isGroup) {
      if (this.props.onBlur) {
        this.props.onBlur(this.setTouchedWhenNotEmpty, {
          event: e,
        });
      } else {
        this.setTouchedWhenNotEmpty();
      }
    }

    this.timeout = setTimeout(() => {
      const error = this.getError();
      const touched = this.getTouched();
      const invalidGroup = this.getInvalidGroup();
      const invalid = this.getInvalid(error, touched, invalidGroup);

      const fieldErrorAnnouncement = invalid ? error : '';

      if (
        getIn(this.props, 'form.status.fieldErrorAnnouncement') !==
        fieldErrorAnnouncement
      ) {
        this.props.form.setStatus({ fieldErrorAnnouncement });
      }
    }, 10);
  };

  setTouchedWhenNotEmpty = () => {
    this.setFieldValue.flush();

    if (!this.getEmpty()) {
      this.props.form.setFieldTouched(this.props.field.name);
    }
  };

  unwrapValue = (value) => {
    // For the special case of { raw, value } such as TelephoneInput
    if (typeof value === 'object' && typeof value.value !== 'undefined') {
      return value.value;
    }

    return value;
  };

  getEmpty = () => isEmpty(this.unwrapValue(this.state.value));

  getError = () => {
    const error = getIn(this.props.form.errors, this.props.field.name);
    const unwrappedError = this.unwrapValue(error);
    return typeof unwrappedError === 'string' ? unwrappedError : undefined;
  };

  getTouched = () => {
    const touched = getIn(this.props.form.touched, this.props.field.name);

    if (typeof touched === 'object' && !Array.isArray(touched)) {
      // Must be a group of fields, check they're all touched

      const fieldsMappedToFalse = mapValues(
        () => false,
        getIn(this.props.form.initialValues, this.props.field.name)
      );

      return Object.values({ ...fieldsMappedToFalse, ...touched }).every(
        identity
      );
    }

    return !!touched;
  };

  getInvalid = (error, touched, invalidGroup) => {
    const hasError = typeof error === 'string';
    const submitted = this.props.form.submitCount > 0;
    const empty = this.getEmpty();

    if (touched && (submitted || !empty) && (hasError || invalidGroup)) {
      return true;
    }

    return false;
  };

  getInvalidGroup = () => {
    if (this.props.context) {
      const { touched, error, name } = this.props.context;
      return touched && typeof error === 'string' && name;
    }

    return false;
  };

  render() {
    const {
      field: { name },
      ...rest
    } = omit(
      [
        'form',
        'context',
        'onChange',
        'onBlur',
        'meta',
        'debounceWait',
        'invalidateNestedFields',
      ],
      this.props
    );

    const error = this.getError();
    const touched = this.getTouched();
    const invalidGroup = this.getInvalidGroup();

    const invalid = this.getInvalid(error, touched, invalidGroup);

    // If the containing group is invalid, create an additional string for aria-describedby
    // using the name of the group to reconstruct the group's FieldFeedback id.
    // Note: This assumes the group's Fieldset has a FieldFeedback.
    const extraDescriber =
      (invalid && invalidGroup && `${invalidGroup}-error-message`) || null;

    return (
      <FieldProvider
        name={name}
        value={this.state.value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={error}
        touched={touched}
        invalid={invalid}
        extraDescriber={extraDescriber}
        {...rest}
      />
    );
  }
}

export { FormikAdapter };

export default withContext(Consumer, (value) => ({ context: value }))(
  FormikAdapter
);
