import React from 'react';
import { useTrackingContext } from './TrackingContext';
import pick from 'lodash/fp/pick';
import noop from 'lodash/noop';
import hoistStatics from 'hoist-non-react-statics';

const getDefaultContextValues = (value) => pick(['track'])(value);

const ContextProvider = ({ children, mapContextToProps }) => {
  const trackingContext = useTrackingContext();
  const userContextProps = mapContextToProps(trackingContext);
  const defaultContextProps = getDefaultContextValues(trackingContext);

  return children({ ...userContextProps, ...defaultContextProps });
};

/**
 * Component wrapped by this HOC will get `track` by default and additinal values extracted by mapContextToProps
 * Useful to provide TrackingContext values to the Class Components.
 *
 * @param {function} mapContextToProps Extract properties form TrackingContext
 */
const withTrackingContext = (mapContextToProps = noop) => (Component) => {
  const Wrapped = React.forwardRef((props, ref) => (
    <ContextProvider mapContextToProps={mapContextToProps}>
      {(ctxProps) => <Component {...props} {...ctxProps} ref={ref} />}
    </ContextProvider>
  ));

  Wrapped.displayName = `withTrackingContext(${
    Component.displayName || Component.name || 'Component'
  })`;

  Wrapped.WrappedComponent = Component;

  return hoistStatics(Wrapped, Component, { propTypes: true });
};

export default withTrackingContext;
