import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import kebabCase from 'lodash/fp/kebabCase';
import omit from 'lodash/fp/omit';
import Reveal from '../Reveal';
import Link from '../Link';
import eventBus from '../../utils/eventBus';

import { IconChevronDown, IconCloseThick, IconAddThick } from '../Icon';

import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';
import Theme from '../../utils/Theme';

import styles from './expander.css';

export default class Expander extends Component {
  static propTypes = {
    name: PropTypes.string,
    /**
     * proxied to eventBus
     */
    onOpen: PropTypes.func,
    /**
     * proxied to eventBus
     */
    onClose: PropTypes.func,
    children: PropTypes.node.isRequired,
    id: PropTypes.string,
    startExpanded: PropTypes.bool,
    /**
     * 0, 1, 2, 3, 4, 5, 6, 7
     */
    marginBottom: PropTypes.oneOf(marginBottomLevels),
    title: PropTypes.string.isRequired,
    textMap: PropTypes.shape({
      closeButton: PropTypes.node,
    }),
    _unsafeTitleSlot: PropTypes.func,
  };

  static defaultProps = {
    textMap: {
      closeButton: 'Close',
    },
    startExpanded: false,
    marginBottom: 2,
  };

  constructor(props) {
    super(props);
    this.id = props.id || kebabCase(props.title);
    this.toggleButton = React.createRef();

    this.state = {
      expanded: props.startExpanded,
    };
  }

  handleToggle = (event) => {
    event.preventDefault();

    if (this.state.expanded && this.toggleButton.current) {
      this.toggleButton.current.focus();
    }

    eventBus.dispatch({
      type: this.state.expanded ? 'onClose' : 'onOpen',
      component: 'Expander',
      ...(this.props.name && { name: this.props.name }),
      title: this.props.title,
    });

    this.setState((state) => ({ expanded: !state.expanded }));

    if (this.props.onClose && this.state.expanded) {
      this.props.onClose(event);
    }
    if (this.props.onOpen && !this.state.expanded) {
      this.props.onOpen(event);
    }
  };

  render() {
    const {
      title,
      textMap,
      marginBottom,
      children,
      _unsafeTitleSlot,
      ...rest
    } = omit(['onOpen', 'onClose', 'startExpanded'], this.props);

    const { expanded } = this.state;

    const strongLink = Theme.select({
      redbrand: false,
      blackbrand: false,
      mands: true,
    });

    const Icon = Theme.select({
      redbrand: IconChevronDown,
      blackbrand: IconChevronDown,
      mands: expanded ? IconCloseThick : IconAddThick,
    });

    return (
      <div
        id={this.id}
        className={cn(styles.Expander, getMarginBottomClass(marginBottom))}
        {...rest}
      >
        <div>
          <button
            className={cn(styles.toggleButton, {
              [styles['toggleButton--expanded']]: expanded,
            })}
            type="button"
            aria-expanded={expanded}
            aria-controls={`${this.id}-expander`}
            ref={this.toggleButton}
            onClick={this.handleToggle}
          >
            <span className={styles.icon}>
              <Icon marginBottom={0} />
            </span>
            {_unsafeTitleSlot ? _unsafeTitleSlot() : title}
          </button>
        </div>
        <Reveal id={`${this.id}-expander`}>
          {expanded && (
            <div>
              <div className={styles.content}>{children}</div>
              <div className={styles.closeButtonContainer}>
                <Link
                  elementType="button"
                  type="button"
                  strong={strongLink}
                  aria-controls={`${this.id}-expander`}
                  onClick={this.handleToggle}
                  buttonPadding
                  name={`${this.props.name}CloseButton`}
                >
                  {textMap.closeButton}
                </Link>
              </div>
            </div>
          )}
        </Reveal>
      </div>
    );
  }
}
