import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import pick from 'lodash/fp/pick';
import debounce from 'lodash/debounce';
import omit from 'lodash/fp/omit';
import cn from 'classnames';
import {
  Announce,
  getMarginBottomClass,
  marginBottomLevels,
  eventBus,
} from '@piggybank/core';

class FormikForm extends Component {
  static propTypes = {
    formik: PropTypes.shape({
      errors: PropTypes.object,
      isSubmitting: PropTypes.bool,
      isValidating: PropTypes.bool,
      resetForm: PropTypes.func,
      setErrors: PropTypes.func,
      setFieldError: PropTypes.func,
      setFieldTouched: PropTypes.func,
      setFieldValue: PropTypes.func,
      setStatus: PropTypes.func,
      setSubmitting: PropTypes.func,
      setTouched: PropTypes.func,
      setValues: PropTypes.func,
      submitCount: PropTypes.number,
      values: PropTypes.object,
    }).isRequired,
    /**
     * 0, 1, 2, 3, 4, 5, 6, 7
     */
    marginBottom: PropTypes.oneOf(marginBottomLevels),
    name: PropTypes.string,
    textMap: PropTypes.shape({
      errorAnnouncementPrefix: PropTypes.string,
    }),
    onError: PropTypes.func,
    debouncedOnChange: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  };

  static defaultProps = {
    onError: () => {},
  };

  constructor(props) {
    super(props);

    if (this.props.debouncedOnChange) {
      this.createDebouncedOnChange(this.props.debouncedOnChange);
    }

    this.state = {
      submitCount: 0,
    };
  }

  static getDerivedStateFromProps(props) {
    if (
      !props.formik.isSubmitting &&
      !props.formik.isValidating &&
      props.formik.submitCount > 0
    ) {
      return { submitCount: props.formik.submitCount };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { formik, debouncedOnChange } = this.props;

    if (
      debouncedOnChange &&
      debouncedOnChange !== prevProps.debouncedOnChange
    ) {
      this.createDebouncedOnChange(debouncedOnChange);
    }

    if (this.handleChangeIdle && formik.values !== prevProps.formik.values) {
      this.handleChangeIdle.cancel();
      this.handleChangeIdle(formik.values);
    }

    // Only try firing onError when form is submitted
    if (
      !formik.isSubmitting &&
      !formik.isValidating &&
      formik.submitCount > 0 &&
      this.state.submitCount > prevState.submitCount &&
      !isEmpty(formik.errors)
    ) {
      eventBus.dispatch({
        type: 'onError',
        component: 'Form',
        name: prevProps.name,
        values: formik.values,
        errors: formik.errors,
      });
      // Create formikBag as per
      // https://github.com/jaredpalmer/formik/blob/master/docs/api/withFormik.md#handlesubmit-values-values-formikbag-formikbag--void
      const formikBag = {
        ...this.props,
        ...pick(
          [
            'resetForm',
            'setErrors',
            'setFieldError',
            'setFieldTouched',
            'setFieldValue',
            'setStatus',
            'setSubmitting',
            'setTouched',
            'setValues',
          ],
          formik
        ),
      };

      this.props.onError({
        errors: formik.errors,
        values: formik.values,
        formikBag,
      });
    }
  }

  createDebouncedOnChange = (debouncedOnChange) => {
    this.handleChangeIdle = debounce(debouncedOnChange, 300);
  };

  render() {
    const { formik, marginBottom, textMap, children, ...rest } = omit(
      ['debouncedOnChange'],
      this.props
    );

    let announcement = '';

    if (formik.status && formik.status.fieldErrorAnnouncement) {
      announcement = `${textMap.errorAnnouncementPrefix}${formik.status.fieldErrorAnnouncement}`;
    }

    return (
      <form
        className={cn('Form', getMarginBottomClass(marginBottom))}
        onSubmit={formik.handleSubmit}
        noValidate
        {...rest}
      >
        <Announce>{announcement}</Announce>
        {typeof children === 'function' ? children(formik) : children}
      </form>
    );
  }
}

FormikForm.displayName = 'FormikForm';

export default FormikForm;
