import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/fp/pick';
import { withContext, eventBus } from '@piggybank/core';
import { TextInput } from '../TextInput';
import isValidInteger from '../../utils/isValidInteger';
import { Consumer } from '../Field/context';

export const leadsWithZero = (value) => {
  return value.startsWith('0') && value.length > 1;
};

const NumberInput = React.forwardRef(
  (
    { allowLeadingZeros, onChange, name, disableAriaLabel, ariaLabel, ...rest },
    ref
  ) => {
    const handleChange = (value, event) => {
      eventBus.dispatch({
        type: 'onChange',
        component: 'NumberInput',
        value,
        name,
      });
      onChange && onChange({ value, event });
    };

    const handleNumberChange = ({ value, event }) => {
      if (value === '') {
        return handleChange('', event);
      }
      if (
        isValidInteger(value) &&
        (allowLeadingZeros || !leadsWithZero(value))
      ) {
        handleChange(value, event);
      }
    };

    const getAriaLabel = () => {
      if (disableAriaLabel) {
        return undefined;
      } else if (ariaLabel) {
        return ariaLabel;
      } else {
        return 'number input';
      }
    };

    return (
      <TextInput
        name={name}
        aria-label={getAriaLabel()}
        onChange={handleNumberChange}
        pattern="\d*"
        type="tel"
        ref={ref}
        {...rest}
      />
    );
  }
);

NumberInput.displayName = 'NumberInput';

NumberInput.defaultProps = {
  allowLeadingZeros: true,
  disableAriaLabel: false,
};

NumberInput.propTypes = {
  ...TextInput.propTypes,
  allowLeadingZeros: PropTypes.bool,
  disableAriaLabel: PropTypes.bool,
};

export { NumberInput };

export default withContext(
  Consumer,
  pick([
    'name',
    'value',
    'onChange',
    'onBlur',
    'invalid',
    'required',
    'describers',
  ])
)(NumberInput);
