import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import pick from 'lodash/fp/pick';
import Textarea from 'react-textarea-autosize';
import {
  getMarginBottomClass,
  marginBottomLevels,
  withContext,
  eventBus,
} from '@piggybank/core';
import { Consumer } from '../Field/context';

import styles from './text-area.css';

const getOnChangeHandler = (fn, name) => (event) => {
  fn({ value: event.target.value, event });
  eventBus.dispatch({
    type: 'onChange',
    component: 'TextArea',
    value: event.target.value,
    name,
  });
};

const TextArea = React.forwardRef(
  (
    {
      minRows = 3,
      invalid = false,
      disableResize = false,
      marginBottom = 0,
      name,
      onChange,
      value = '',
      required = true,
      describers = null,
      ...rest
    },
    ref
  ) => (
    <Textarea
      useCacheForDOMMeasurements
      minRows={minRows}
      aria-invalid={invalid}
      aria-describedby={describers}
      className={cn(styles.TextArea, getMarginBottomClass(marginBottom), {
        [styles.Invalid]: invalid,
        [styles.Resize]: disableResize,
      })}
      id={`${name}-field`}
      name={name}
      onChange={getOnChangeHandler(onChange, name)}
      ref={ref}
      type="text"
      required={required}
      value={value}
      {...rest}
    />
  )
);

TextArea.displayName = 'TextArea';

TextArea.propTypes = {
  minRows: PropTypes.number,
  /** docgen-from-context:<Field/> */
  invalid: PropTypes.bool,
  disableResize: PropTypes.bool,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  /** docgen-from-context:<Field/> */
  name: PropTypes.string.isRequired,
  /** docgen-from-context:<Field/> */
  onBlur: PropTypes.func,
  /**
   * docgen-from-context:<Field/>
   * ---
   * proxied to eventBus
   */
  onChange: PropTypes.func.isRequired,
  /** docgen-from-context:<Field/> */
  value: PropTypes.string,
  /** docgen-from-context:<Field/> */
  describers: PropTypes.string,
  /** docgen-from-context:<Field/> */
  required: PropTypes.bool,
};

TextArea.defaultProps = {
  minRows: 3,
  marginBottom: 0,
};

export { TextArea };

export default withContext(
  Consumer,
  pick([
    'name',
    'value',
    'onChange',
    'onBlur',
    'invalid',
    'required',
    'describers',
  ])
)(TextArea);
