import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/fp/omit';
import cn from 'classnames';
import { marginBottomLevels, getMarginBottomClass } from '@piggybank/core';
import Tile from '../Tile';

import styles from './icon-tile.css';

const IconTile = ({
  children,
  fullWidth,
  marginBottom,
  cancelEdgeMargin,
  ...tileProps
}) => {
  useLayoutEffect(() => {
    if (cancelEdgeMargin) {
      cancelEdgeMargin(marginBottom);
    }
  }, [cancelEdgeMargin, marginBottom]);

  return (
    <div
      className={cn(styles.iconTile, getMarginBottomClass(marginBottom), {
        [styles.twoColumns]: !fullWidth,
      })}
    >
      <Tile {...tileProps}>
        {({ Input, inputProps, Label, labelProps, icon: Icon, ...rest }) => (
          <div className={cn(styles.content)}>
            <span className={cn(styles.icon)}>
              <Icon width="1.5em" marginBottom={0} />
            </span>
            <span className={cn(styles.label)}>
              <Label {...labelProps} marginBottom={0}>
                {children}
              </Label>
            </span>
            <span className={cn(styles.input)}>
              <Input {...omit(['focussed'], rest)} {...inputProps} />
            </span>
          </div>
        )}
      </Tile>
    </div>
  );
};

IconTile.propTypes = {
  cancelEdgeMargin: PropTypes.func,
  children: PropTypes.node.isRequired,
  icon: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

IconTile.defaultProps = {
  fullWidth: false,
  marginBottom: 2,
};

export default IconTile;
