import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';
import Panel from '../Panel';
import { Grid, Row, Cell } from '../Grid';

import styles from './product-basket.css';

const ProductBasket = ({ children, imageSlot, type, marginBottom = 5 }) => (
  <div className={cn(styles.ProductBasket, getMarginBottomClass(marginBottom))}>
    <Panel type={type}>
      <Grid>
        <Row>
          <Cell sizeLarge={10} offsetLarge={1}>
            <div className={styles.flexContainer}>
              {imageSlot && <div className={styles.image}>{imageSlot}</div>}
              <div>{children}</div>
            </div>
          </Cell>
        </Row>
      </Grid>
    </Panel>
  </div>
);

ProductBasket.displayName = 'ProductBasket';

ProductBasket.propTypes = {
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  type: PropTypes.oneOf(['default', 'charcoal']),
  imageSlot: PropTypes.node,
  children: PropTypes.node,
};

export { ProductBasket };

export default ProductBasket;
