/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';

export default {
  title: 'HSBC',
  viewBox: '0 0 171 46',
  svgContent: (
    <>
      <polygon fill="#DB0011" points="91.87 23 69 0 69 46" />
      <polygon fill="#DB0011" points="46 23 69 0 23 0" />
      <polygon fill="#DB0011" points="0 23 23 46 23 0" />
      <polygon fill="#DB0011" points="46 23 23 46 69 46" />
      <polygon
        fill="#000000"
        points="112.16 24.42 103.76 24.42 103.76 32.56 99.62 32.56 99.62 13.44 103.76 13.44 103.76 21.32 112.16 21.32 112.16 13.44 116.29 13.44 116.29 32.56 112.16 32.56"
      />
      <path
        d="M126.24,32.95 C122.11,32.95 118.62,31.27 118.62,26.75 L122.75,26.75 C122.75,28.82 124.04,29.98 126.24,29.98 C127.92,29.98 129.86,29.08 129.86,27.27 C129.86,25.72 128.57,25.33 126.37,24.69 L124.95,24.3 C121.95,23.4 118.88,22.23 118.88,18.74 C118.88,14.48 122.88,13.05 126.5,13.05 C130.12,13.05 133.5,14.34 133.5,18.61 L129.37,18.61 C129.243333,16.8033333 128.21,15.9 126.27,15.9 C124.72,15.9 123.17,16.68 123.17,18.48 C123.17,19.9 124.46,20.29 127.17,21.19 L128.72,21.71 C132.08,22.71 134.15,23.91 134.15,27.14 C134.28,31.27 130.15,32.95 126.27,32.95"
        fill="#000000"
      />
      <path
        d="M136.71,13.44 L143.43,13.44 C144.685981,13.3410613 145.94969,13.4286785 147.18,13.7 C149.51,14.22 151.31,15.77 151.31,18.35 C151.31,20.93 149.76,22.1 147.43,22.74 C150.01,23.26 151.95,24.55 151.95,27.39 C151.95,31.78 147.69,32.82 144.2,32.82 L136.71,32.82 L136.71,13.44 Z M143.3,21.44 C145.11,21.44 147.05,21.05 147.05,18.86 C147.05,16.92 145.24,16.28 143.56,16.28 L140.56,16.28 L140.56,21.45 L143.3,21.44 Z M143.82,29.71 C145.76,29.71 147.7,29.19 147.7,26.87 C147.7,24.55 146,24 144.07,24 L140.71,24 L140.71,29.56 L143.81,29.56 L143.82,29.71 Z"
        fill="#000000"
      />
      <path
        d="M162.81,32.95 C156.61,32.95 153.81,28.95 153.81,23.13 C153.81,17.31 156.91,13.05 162.98,13.05 C166.86,13.05 170.6,14.73 170.6,19.12 L166.3,19.12 C166.256222,18.2751543 165.876565,17.482856 165.245517,16.9194206 C164.61447,16.3559851 163.784389,16.0681611 162.94,16.12 C159.19,16.12 158.03,20.12 158.03,23.23 C158.03,26.34 159.19,29.82 162.81,29.82 C164.623367,29.9446103 166.215733,28.6249702 166.43,26.82 L170.82,26.82 C170.3,31.21 166.82,32.89 162.82,32.89"
        fill="#000000"
      />
    </>
  ),
};
