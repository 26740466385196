import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import eventBus from '../../utils/eventBus';
import links from '../../utils/withMagicUnderlines.css';
import styles from './link.css';
import ButtonLinkBase from '../ButtonLinkBase';

const Link = React.forwardRef(
  (
    {
      elementType = 'a',
      children,
      iconSlotLeft,
      iconSlotRight,
      strong = false,
      buttonPadding = false,
      hoverUnderline,
      onClick,
      name,
      ...rest
    },
    ref
  ) => {
    return (
      <ButtonLinkBase
        className={cn(styles.Link, {
          [styles['Link--strong']]: strong,
          [styles['Link--likeButton']]: elementType === 'button',
          [styles['Link--buttonPadding']]:
            elementType === 'button' && buttonPadding,
          [styles['Link--withIconSlotLeft']]: iconSlotLeft,
          [styles['Link--withIconSlotRight']]: iconSlotRight,
          [links.withMagicUnderlinesInverted]: hoverUnderline,
        })}
        elementType={elementType}
        iconSlotLeft={iconSlotLeft}
        iconSlotRight={iconSlotRight}
        onClick={(e) => {
          const text = e.target.textContent || '';
          eventBus.dispatch({
            type: 'onClick',
            component: 'Link',
            text: text.trim(),
            name,
          });
          onClick && onClick(e);
        }}
        ref={ref}
        {...rest}
      >
        <span
          className={cn(
            hoverUnderline
              ? links.withMagicUnderlinesChild
              : links.withMagicUnderlines,
            {
              [styles['text']]: elementType === 'button',
            }
          )}
        >
          {children}
        </span>
      </ButtonLinkBase>
    );
  }
);

Link.displayName = 'Link';

Link.propTypes = {
  elementType: PropTypes.oneOf(['button', 'a']),
  /**
   * proxied to eventBus
   */
  onClick: PropTypes.func,
  component: PropTypes.func,
  iconSlotLeft: PropTypes.node,
  iconSlotRight: PropTypes.node,
  children: PropTypes.node,
  href: PropTypes.string,
  strong: PropTypes.bool,
  /**
   * To be used in conjunction with `elementType = 'button'`
   */
  buttonPadding: PropTypes.bool,
  hoverUnderline: PropTypes.bool,
  external: PropTypes.bool,
  textMap: PropTypes.shape({
    newWindow: PropTypes.string,
  }),
  name: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
};

Link.defaultProps = {
  elementType: 'a',
  strong: false,
  buttonPadding: false,
  textMap: {
    newWindow: 'Opens in a new window',
  },
};

export { Link };

export default Link;
