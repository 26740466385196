import React from 'react';
import PropTypes from 'prop-types';

import { Cell, Grid, Row } from '../Grid';

const FormLayout = (props) => (
  <Grid>
    <Row>
      <Cell offsetLarge={1} size={12} sizeMedium={9} sizeLarge={6} {...props} />
    </Row>
  </Grid>
);

FormLayout.displayName = 'FormLayout';

FormLayout.propTypes = {
  children: PropTypes.node,
};

export default FormLayout;
