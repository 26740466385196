import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Consumer } from '../Wizard/context';
import {
  withContext,
  getMarginBottomClass,
  marginBottomLevels,
  detectBrowser,
} from '@piggybank/core';

import styles from './progress-indicator.css';

const inDevelopment = process.env.NODE_ENV === 'development';

class ProgressIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = { mounted: !this.props.last };
  }

  componentDidMount() {
    if (this.props.last) {
      setTimeout(() => {
        this.setState({ mounted: true });
      }, 0);
    }
  }

  render() {
    const {
      current,
      last,
      textMap,
      renderStepText,
      marginBottom,
      total,
      stageTitles,
      ...props
    } = this.props;

    if (inDevelopment && stageTitles && stageTitles.length != total) {
      throw new Error(`[WARNING]: ProgressIndicator - When using stageTitles you must provide a title for each step
      (The length of stageTitle array must equal the value of total prop)`);
    }

    return (
      <div
        className={cn('ProgressIndicator', getMarginBottomClass(marginBottom))}
        aria-live={detectBrowser.iOS ? false : 'polite'}
        aria-atomic={detectBrowser.iOS ? false : 'true'}
        {...props}
      >
        <div className={styles.labelWrapper}>
          {stageTitles && stageTitles[current - 1] && (
            <span>
              <span className={styles.stageTitle}>
                {`${stageTitles[current - 1]}`}
              </span>
              <span
                className={styles.stageTitleDivider}
                role="none"
              >{` | `}</span>
            </span>
          )}
          {renderStepText ? (
            renderStepText({ current, total })
          ) : (
            <>
              <span className={cn({ [styles.stepLabel]: !stageTitles })}>
                {textMap.step}
              </span>
              {` ${current} ${textMap.of} ${total}`}
            </>
          )}
        </div>
        <div className={styles.progressWrapper} aria-hidden="true">
          <div className={styles.bar}>
            <div
              className={styles.indicator}
              style={{
                transform: `translateX(${
                  ((this.state.mounted ? current : last) / total - 1) * 100
                }%)`,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

ProgressIndicator.displayName = 'ProgressIndicator';

ProgressIndicator.propTypes = {
  /** docgen-from-context:<Wizard/> */
  current: PropTypes.number.isRequired,
  /** docgen-from-context:<Wizard/> */
  last: PropTypes.number,
  textMap: PropTypes.shape({
    step: PropTypes.node,
    of: PropTypes.node,
  }),
  renderStepText: PropTypes.func,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  /** docgen-from-context:<Wizard/> */
  total: PropTypes.number.isRequired,
  stageTitles: PropTypes.arrayOf(PropTypes.string),
};

ProgressIndicator.defaultProps = {
  marginBottom: 4,
  textMap: {
    step: 'Step',
    of: 'of',
  },
};

export { ProgressIndicator };

export default withContext(Consumer, (value) => ({
  current:
    typeof value.currentPage === 'number' ? value.currentPage + 1 : undefined,
  last:
    typeof value.lastVisitedPage === 'number'
      ? value.lastVisitedPage + 1
      : undefined,
  total: value.totalPages,
}))(ProgressIndicator);
