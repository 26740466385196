import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
  withContext,
} from '@piggybank/core';
import { Provider } from './context';
import { Consumer } from '../Field/context';

import styles from './label.css';

const Label = ({ name, children, marginBottom = 2, ...rest }) => {
  return (
    <label
      className={cn(styles['Label'], getMarginBottomClass(marginBottom))}
      htmlFor={name ? `${name}-field` : null}
      id={name ? `${name}-field-label` : null}
      {...rest}
    >
      <Provider value={{ inLabel: true }}>{children}</Provider>
    </label>
  );
};

Label.propTypes = {
  /** docgen-from-context:<Field/> */
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

export { Label };

export default withContext(Consumer, (value) => ({ name: value.name }))(Label);
