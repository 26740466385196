import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import { Field as FormikField, connect } from 'formik';

import FieldProvider from './FieldProvider';
import FormikAdapter from './FormikAdapter';

import { marginBottomLevels } from '@piggybank/core';

// connect() higher-order component from Formik package provides formik prop
const Field = connect(
  ({
    name,
    formik,
    onChange,
    debounceWait,
    invalidateNestedFields,
    ...rest
  }) => {
    return isEmpty(formik) || !name ? (
      <FieldProvider name={name} onChange={onChange || (() => {})} {...rest} />
    ) : (
      <FormikField name={name}>
        {(fieldProps) => (
          <FormikAdapter
            onChange={onChange}
            debounceWait={debounceWait}
            invalidateNestedFields={invalidateNestedFields}
            {...fieldProps}
            {...rest}
          />
        )}
      </FormikField>
    );
  }
);

Field.displayName = 'Field';

Field.propTypes = {
  /** docgen-from-context:<Form/> */
  debounceWait: PropTypes.number,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  name: PropTypes.string.isRequired,
  /** docgen-from-context:<Form/> */
  value: PropTypes.any,
  /** docgen-from-context:<Form/> */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** docgen-from-context:<Form/> */
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  /** docgen-from-context:<Form/> */
  invalid: PropTypes.bool,
  /** docgen-from-context:<Form/> */
  onChange: PropTypes.func,
  /** docgen-from-context:<Form/> */
  onBlur: PropTypes.func,
  /** docgen-from-context:<Form/> */
  required: PropTypes.bool,
  children: PropTypes.node,
};

Field.defaultProps = {
  marginBottom: 5,
};

export { Field };

export default Field;
