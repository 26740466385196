import React from 'react';
import PropTypes from 'prop-types';

import styles from './button-link-base.css';
import cn from 'classnames';

import { IconNewWindow } from '../Icon';
import { VisuallyHidden } from '../VisuallyHidden';

const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : <>{children}</>;

ConditionalWrap.propTypes = {
  condition: PropTypes.bool,
  wrap: PropTypes.func,
  children: PropTypes.node,
};

const ButtonLinkBase = React.forwardRef(
  (
    {
      elementType,
      component,
      children,
      external,
      primaryButton,
      iconSlotRight,
      iconSlotLeft,
      target,
      rel,
      textMap = {
        newWindow: 'Opens in a new window',
      },
      className: restClassNames,
      ...rest
    },
    ref
  ) => {
    const isAnchorElementAndExternal = !!(elementType === 'a' && external);

    const isButtonElementWithIcon = !!(
      elementType === 'button' &&
      (iconSlotLeft || iconSlotRight)
    );

    const variableProps = isAnchorElementAndExternal && {
      target: target || '_blank',
      rel: rel || 'noreferrer',
    };

    // Defaults External Anchor Elements to have Open In New Window Icon in right hand slot.
    iconSlotRight =
      iconSlotRight ||
      (!iconSlotLeft && isAnchorElementAndExternal && !primaryButton && (
        <IconNewWindow inline />
      ));

    const Component = component || elementType;
    return (
      <Component
        className={cn(restClassNames, {
          [styles['anchor']]: elementType === 'a',
          [styles['hasMaxWidth']]: isButtonElementWithIcon,
        })}
        ref={ref}
        {...variableProps}
        {...rest}
      >
        <ConditionalWrap
          condition={isButtonElementWithIcon}
          wrap={(children) => (
            <span className={styles.flexContainer}>{children}</span>
          )}
        >
          {iconSlotLeft && (
            <span aria-hidden="true" className={styles.iconLeft}>
              {iconSlotLeft}
              &#65279;
            </span>
          )}
          {/* FYI: &#65279; is a zero-width non-breaking space */}

          {isButtonElementWithIcon ? (
            <span
              className={cn({
                [styles['buttonTextLeft']]: iconSlotLeft,
                [styles['buttonTextRight']]: iconSlotRight,
              })}
            >
              {children}
            </span>
          ) : (
            <>{children}</>
          )}

          {isAnchorElementAndExternal && (
            <VisuallyHidden> {textMap.newWindow} </VisuallyHidden>
          )}

          {iconSlotRight && (
            <span aria-hidden="true" className={styles.iconRight}>
              &#65279;
              {iconSlotRight}
            </span>
          )}
          {/* FYI: &#65279; is a zero-width non-breaking space */}
        </ConditionalWrap>
      </Component>
    );
  }
);

ButtonLinkBase.displayName = 'ButtonLinkBase';

ButtonLinkBase.propTypes = {
  elementType: PropTypes.oneOf(['button', 'a']).isRequired,
  component: PropTypes.func,
  external: PropTypes.bool,
  primaryButton: PropTypes.bool,
  children: PropTypes.node,
  iconSlotLeft: PropTypes.node,
  iconSlotRight: PropTypes.node,
  target: PropTypes.string,
  rel: PropTypes.string,
  textMap: PropTypes.shape({
    newWindow: PropTypes.string,
  }),
  className: PropTypes.string,
};

export { ButtonLinkBase };

export default ButtonLinkBase;
