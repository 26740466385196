import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';

import styles from './image.css';

const Image = ({
  src,
  alt,
  width = '100%',
  intrinsicSize,
  inline,
  marginBottom = 4,
  ...rest
}) => {
  let stretcher = null;

  if (intrinsicSize) {
    const [intrinsicWidth, intrinsicHeight] = intrinsicSize.split('x');

    stretcher = (
      <canvas
        className={styles.stretcher}
        width={intrinsicWidth}
        height={intrinsicHeight}
        aria-hidden
      />
    );
  }

  return (
    <span
      className={cn(
        styles.Image,
        { [styles.inline]: inline },
        getMarginBottomClass(marginBottom)
      )}
      style={{ width }}
    >
      {stretcher}
      <img
        className={cn(styles.img, { [styles.intrinsicSize]: intrinsicSize })}
        src={src}
        alt={alt}
        {...rest}
      />
    </span>
  );
};

Image.displayName = 'Image';

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  intrinsicSize: PropTypes.string,
  inline: PropTypes.bool,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

export { Image };

export default Image;
