/* eslint-env browser */
import { detectBrowser } from '@piggybank/core';

function getRelatedTarget(event) {
  // Blur `event.relatedTarget` doesn't work in some browsers in React :(
  // https://github.com/facebook/react/issues/3751
  if (detectBrowser.ie11 || detectBrowser.safari) {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(document.activeElement);
      }, 30)
    );
  }

  return Promise.resolve(event.relatedTarget);
}

async function isBlurringOutsideRefs(event, inputRefs) {
  const relatedTarget = await getRelatedTarget(event);

  return (
    !relatedTarget ||
    !Object.values(inputRefs)
      .map((ref) => ref.current)
      .includes(relatedTarget)
  );
}

export default isBlurringOutsideRefs;
