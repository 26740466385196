import React from 'react';
import { withContext } from '@piggybank/core';

import { Consumer } from '../Field/context';
import TextInput from '../TextInput';
import TelephoneInputInner from './TelephoneInputInner';

import metadata from 'libphonenumber-js/metadata.min.json.js';

const TelephoneInput = React.forwardRef((props, ref) => (
  <TelephoneInputInner {...props} forwardedRef={ref} metadata={metadata} />
));

TelephoneInput.displayName = 'TelephoneInput';

TelephoneInput.propTypes = {
  ...TelephoneInputInner.inputPropTypes,
  ...TextInput.propTypes,
};

export { TelephoneInput };

export default withContext(Consumer, (value) => ({
  invalid: value.invalid,
  describers: value.describers,
  name: value.name,
  onBlur: value.onBlur,
  onChange: value.onChange,
  value:
    typeof value.value === 'string'
      ? { countryCode: '', raw: value.value, value: '' }
      : value.value,
}))(TelephoneInput);
