import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import isNumber from 'lodash/fp/isNumber';
import {
  Heading,
  HorizontalRule,
  IconEdit,
  Link,
  VisuallyHidden,
  withContext,
  combineEventHandlers,
  getMarginBottomClass,
  marginBottomLevels,
} from '@piggybank/core';

import { Consumer } from '../Wizard/context';

import styles from './review-navigate.css';

/** docgen-ignore */
const Review = ({
  title,
  onClick,
  textMap = { editButton: null },
  children,
  marginBottom = 7,
  iconSlot,
  accentBar,
  noHorizontalBar,
  _unsafeTitleSlot,
}) => (
  <div className={getMarginBottomClass(marginBottom)}>
    <div className={styles.header}>
      <div className={styles.heading}>
        <Heading level={2} marginBottom={3} accentBar={accentBar}>
          {_unsafeTitleSlot ? _unsafeTitleSlot() : title}
        </Heading>
      </div>
      <div className={styles.button}>
        <Link
          elementType="button"
          type="button"
          onClick={onClick}
          iconSlotRight={iconSlot}
          strong
          buttonPadding
          hoverUnderline
        >
          {textMap.editButton || (
            <>
              Edit
              <VisuallyHidden>
                {' '}
                {_unsafeTitleSlot ? _unsafeTitleSlot() : title}
              </VisuallyHidden>
            </>
          )}
        </Link>
      </div>
    </div>
    {!noHorizontalBar && <HorizontalRule aria-hidden marginBottom={4} />}
    {children}
  </div>
);

Review.displayName = 'Review';

Review.propTypes = {
  title: PropTypes.string.isRequired,
  iconSlot: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  textMap: PropTypes.shape({
    editButton: PropTypes.node,
  }),
  children: PropTypes.node,
  accentBar: PropTypes.bool,
  noHorizontalBar: PropTypes.bool,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  _unsafeTitleSlot: PropTypes.func,
};

const ReviewNavigate = (props) => (
  <Review {...props} iconSlot={<IconEdit inline />} />
);

ReviewNavigate.displayName = 'ReviewNavigate';

ReviewNavigate.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  textMap: PropTypes.shape({
    editButton: PropTypes.node,
  }),
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  /** docgen-from-context:<Wizard/> */
  navigateTo: PropTypes.number,
  _unsafeTitleSlot: PropTypes.func,
};

ReviewNavigate.defaultProps = {
  marginBottom: 7,
};

export { Review, ReviewNavigate };

const ReviewNavigateWithContext = withContext(Consumer, (value, ownProps) => ({
  onClick: combineEventHandlers(
    ownProps.onClick,
    !isEmpty(value) && isNumber(ownProps.navigateTo)
      ? () => {
          value.navigateTo(ownProps.navigateTo);
        }
      : null
  ),
}))(ReviewNavigate);

export default ReviewNavigateWithContext;
