import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import pick from 'lodash/fp/pick';
import {
  getMarginBottomClass,
  marginBottomLevels,
  withContext,
  gridStyles,
  eventBus,
} from '@piggybank/core';
import { Consumer } from '../Field/context';

import styles from './text-input.css';

const getOnChangeHandler = (fn, maxLength, name) => (event) => {
  const value = maxLength
    ? event.target.value.slice(0, maxLength)
    : event.target.value;

  eventBus.dispatch({
    type: 'onChange',
    component: 'TextInput',
    value,
    name,
  });

  fn({ value, event });
};

const TextInput = React.forwardRef(
  (
    {
      ariaLabel,
      component: Component = 'input',
      fullWidth,
      invalid = false,
      marginBottom = 0,
      name,
      onChange,
      value = '',
      required = true,
      describers,
      maxLength,
      ...rest
    },
    ref
  ) => {
    return (
      <Component
        aria-label={ariaLabel}
        aria-invalid={invalid}
        aria-describedby={describers}
        className={cn(styles['TextInput'], getMarginBottomClass(marginBottom), {
          [styles.Invalid]: invalid,
          [styles.fullWidth]: fullWidth,
          [gridStyles['size-12']]: !fullWidth,
          [gridStyles['sizeMedium-7']]: !fullWidth,
          [gridStyles['sizeLarge-4']]: !fullWidth,
        })}
        id={`${name}-field`}
        name={name}
        onChange={getOnChangeHandler(onChange, maxLength, name)}
        ref={ref}
        type="text"
        required={required}
        value={value}
        maxLength={maxLength}
        {...rest}
      />
    );
  }
);

TextInput.displayName = 'TextInput';

TextInput.propTypes = {
  ariaLabel: PropTypes.string,
  /** docgen-ignore */
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  fullWidth: PropTypes.bool,
  /** docgen-from-context:<Field/> */
  invalid: PropTypes.bool,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  /** docgen-from-context:<Field/> */
  name: PropTypes.string.isRequired,
  /** docgen-from-context:<Field/> */
  onBlur: PropTypes.func,
  /**
   * docgen-from-context:<Field/>
   * ---
   * proxied to eventBus
   */
  onChange: PropTypes.func.isRequired,
  /** docgen-from-context:<Field/> */
  value: PropTypes.string,
  /** docgen-from-context:<Field/> */
  required: PropTypes.bool,
  /** docgen-from-context:<Field/> */
  describers: PropTypes.string,
  maxLength: PropTypes.number,
};

TextInput.defaultProps = {
  ariaLabel: null,
  component: 'input',
  fullWidth: false,
  marginBottom: 0,
};

export { TextInput };

export default withContext(
  Consumer,
  pick([
    'name',
    'value',
    'onChange',
    'onBlur',
    'invalid',
    'required',
    'describers',
  ])
)(TextInput);
