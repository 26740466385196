import { createContext, useContext } from 'react';

const trackingDefaultContext = {
  // eslint-disable-next-line no-console
  track: () => console.log('You should use TrackingWrapper'),
  trackingPageName: '',
  // eslint-disable-next-line no-console
  setTrackingPageName: () => console.log('You should use TrackingWrapper'),
  isTrackingInUse: false,
};
const TrackingContext = createContext(trackingDefaultContext);
export const useTrackingContext = () => useContext(TrackingContext);

export default TrackingContext;
