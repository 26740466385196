import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from '../Wizard/context';
import { withContext, withTrackPage } from '@piggybank/core';

const WizardPage = ({
  children,
  component: Component,
  handleSubmit,
  navigateTo,
  setInitialValues,
  values,
  ...props
}) => {
  const contentProps = {
    handleSubmit,
    navigateTo,
    setInitialValues,
    values,
  };

  let content = children;

  if (Component) {
    content = <Component {...contentProps} />;
  }

  if (typeof children === 'function') {
    content = children(contentProps);
  }

  return <div {...props}>{content}</div>;
};

WizardPage.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  /** docgen-from-context:<Wizard/> */
  handleSubmit: PropTypes.func,
  /** docgen-from-context:<Wizard/> */
  navigateTo: PropTypes.func,
  /** docgen-from-context:<Wizard/> */
  setInitialValues: PropTypes.func,
  /** docgen-from-context:<Wizard/> */
  values: PropTypes.object,
  name: PropTypes.string,
};

export { WizardPage };

const wizardPageWithContext = withContext(Consumer, (value) => ({
  handleSubmit: value.handleSubmit,
  navigateTo: value.navigateTo,
  setInitialValues: value.setInitialValues,
  values: value.values,
}))(WizardPage);

export default withTrackPage()(wizardPageWithContext);
