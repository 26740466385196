import isInteger from 'lodash/fp/isInteger';

const isValidInteger = (value) => {
  return (
    isInteger(+value) &&
    !value.includes('.') &&
    !value.includes('-') &&
    !value.includes(' ') &&
    !value.includes('+')
  );
};

export default isValidInteger;
