/* eslint-env browser */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Modal from 'react-aria-modal';
import { Transition } from 'react-transition-group';
import { Grid, Row, Cell } from '../Grid';
import { LogoHsbcHexagon } from '../Logo';
import setFocus from '../../utils/setFocus';
import Spinner from '../Spinner';

import styles from './loading.css';
import pageStyles from '../Page/page.css';

class Loading extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    getApplicationNode: PropTypes.func,
    logoSlot: PropTypes.node,
    children: PropTypes.node,
    id: PropTypes.string,
    textMap: PropTypes.shape({
      title: PropTypes.string,
    }),
  };

  static defaultProps = {
    getApplicationNode: () => document.querySelector(`.${pageStyles.Page}`),
    textMap: { title: null },
  };

  componentDidUpdate(prevProps) {
    if (this.props.show === false && prevProps.show === true) {
      this.focusTimeout = setTimeout(() => {
        setFocus();
      }, 250);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.focusTimeout);
  }

  render() {
    const {
      show,
      getApplicationNode,
      logoSlot,
      children,
      textMap,
      id,
      ...rest
    } = this.props;

    return (
      <Transition
        in={show}
        timeout={{ enter: 20, exit: 200 }}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <Modal
            id={id}
            titleText={textMap.title}
            titleId={textMap.title ? null : `${id || 'loading'}-content`}
            focusDialog
            includeDefaultStyles={false}
            dialogClass={cn(styles.dialog, styles.fade, {
              [styles['fade--entered']]: state === 'entered',
            })}
            underlayClass={cn(styles.underlay, styles.fade, {
              [styles['fade--entered']]: state === 'entered',
            })}
            getApplicationNode={getApplicationNode}
            underlayClickExits={false}
            escapeExits={false}
            scrollDisabled={false}
            aria-live="assertive"
            aria-relevant="text"
            aria-atomic
            {...rest}
          >
            <Grid>
              <Row>
                <Cell
                  size={12}
                  sizeMedium={8}
                  sizeLarge={6}
                  offsetMedium={2}
                  offsetLarge={3}
                >
                  <div className={styles.dialogContent}>
                    <div className={styles.logo} aria-hidden>
                      {logoSlot || <LogoHsbcHexagon />}
                    </div>
                    <div
                      className={styles.children}
                      id={`${id || 'loading'}-content`}
                    >
                      {children}
                    </div>
                    <div className={styles.icon} aria-hidden>
                      <Spinner />
                    </div>
                  </div>
                </Cell>
              </Row>
            </Grid>
          </Modal>
        )}
      </Transition>
    );
  }
}

export { Loading };

export default Loading;
