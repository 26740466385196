/* eslint-env browser */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import AnimateHeight from 'react-animate-height';
import { CSSTransition } from 'react-transition-group';
import { IconCloseThick, Link, Theme } from '@piggybank/core';

import styles from './add-another.css';

class AddAnotherItem extends Component {
  static propTypes = {
    children: PropTypes.node,
    duration: PropTypes.number,
    easing: PropTypes.string,
    focus: PropTypes.bool,
    focusFirstInput: PropTypes.bool,
    /** docgen-ignore */
    index: PropTypes.number,
    onRemove: PropTypes.func.isRequired,
    removeButtonText: PropTypes.node.isRequired,
    showRemove: PropTypes.bool,
    state: PropTypes.string.isRequired,
  };

  static defaultProps = {
    showRemove: true,
    duration: 200,
  };

  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    this.focus();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.focus) {
      this.focus();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.focusTimeout);
  }

  focus() {
    if (this.props.focus) {
      this.focusTimeout = setTimeout(() => {
        const firstInput = this.ref.current.querySelector('input, select');

        if (firstInput && this.props.focusFirstInput) {
          firstInput.focus();
        } else {
          this.ref.current.focus();
        }
      }, this.props.duration + 50);
    }
  }

  render() {
    const {
      children,
      duration,
      easing,
      focusFirstInput,
      index,
      onRemove,
      removeButtonText,
      state,
      showRemove,
    } = this.props;

    const strongLink = Theme.select({
      redbrand: false,
      mands: true,
    });

    const itemTabIndex = focusFirstInput ? null : '-1';

    return (
      <AnimateHeight
        height={state === 'entered' ? 'auto' : 0}
        duration={duration}
        easing={easing}
      >
        <div
          className={cn(styles.item, { [styles.firstItem]: index === 0 })}
          ref={this.ref}
          tabIndex={itemTabIndex}
        >
          <div className={styles.itemWrapper}>
            <div className={styles.removeContainerSpacer}>
              {removeButtonText}
            </div>
            <div className={styles.itemInner}>{children}</div>
            <CSSTransition
              in={showRemove}
              timeout={150}
              classNames={styles.removeContainer}
            >
              <div
                className={cn(styles.removeContainer, {
                  [styles['removeContainer--hidden']]: !showRemove,
                })}
              >
                <Link
                  iconSlotRight={<IconCloseThick inline />}
                  strong={strongLink}
                  elementType="button"
                  type="button"
                  onClick={(e) => {
                    if (e.target.disabled) return;
                    onRemove(e);
                    e.target.disabled = true;
                  }}
                  hoverUnderline
                  buttonPadding
                >
                  {removeButtonText}
                </Link>
              </div>
            </CSSTransition>
          </div>
        </div>
      </AnimateHeight>
    );
  }
}

AddAnotherItem.displayName = 'AddAnotherItem';

export default AddAnotherItem;
