import React from 'react';
import PropTypes from 'prop-types';
import Radio from '../Radio';
import cn from 'classnames';
import { getMarginBottomClass, marginBottomLevels } from '@piggybank/core';

import styles from './yes-no-radio.css';

const YesNoRadio = ({
  textMap = {
    yes: 'Yes',
    no: 'No',
  },
  values = {
    yes: 'yes',
    no: 'no',
  },
  marginBottom = 0,
  ...rest
}) => {
  return (
    <div className={cn(styles.YesNoRadio, getMarginBottomClass(marginBottom))}>
      <div className={styles.radioItem}>
        <Radio value={values.yes} {...rest}>
          {textMap.yes}
        </Radio>
      </div>
      <div>
        <Radio value={values.no} {...rest}>
          {textMap.no}
        </Radio>
      </div>
    </div>
  );
};

YesNoRadio.propTypes = {
  ...Radio.propTypes,
  textMap: PropTypes.shape({
    yes: PropTypes.node,
    no: PropTypes.node,
  }),
  values: PropTypes.shape({
    yes: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    no: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

YesNoRadio.displayName = 'YesNoRadio';

export default YesNoRadio;
