import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {
  getMarginBottomClass,
  eventBus,
  VisuallyHidden,
  IconNewWindow,
  IconStatusConfirmationOnlight,
} from '../..';
import { marginBottomLevels } from '../../utils/marginBottom';

import styles from './link-tile.css';

const LinkTileStatus = ({ opened, textMap }) => (
  <span className={styles.status}>
    {opened ? (
      <>
        <span className={styles.statusIcon}>
          <IconStatusConfirmationOnlight inline />
        </span>{' '}
        {textMap.opened}
      </>
    ) : (
      textMap.pleaseOpen
    )}
  </span>
);

LinkTileStatus.propTypes = {
  opened: PropTypes.bool.isRequired,
  textMap: PropTypes.shape({
    opened: PropTypes.string.isRequired,
    pleaseOpen: PropTypes.string.isRequired,
  }).isRequired,
};

const isKeyboardEventOtherThanEnter = (event) => {
  return event && event.keyCode !== undefined && event.keyCode !== 13;
};

const LinkTile = React.forwardRef((props, ref) => {
  const {
    displayStatus,
    heading,
    iconSlot,
    marginBottom,
    name,
    opened,
    onClick,
    textMap,
    ...rest
  } = props;

  const [openedState, setOpenedState] = useState(!!opened);

  const actAsControlledComponent = opened !== null && opened !== undefined;

  const onClickWithEventBus = (...args) => {
    if (isKeyboardEventOtherThanEnter(args[0])) {
      return;
    }
    eventBus.dispatch({
      type: 'onClick',
      component: 'LinkTile',
      text: heading,
      name,
    });
    if (!actAsControlledComponent) {
      setOpenedState(true);
    }
    if (typeof onClick === 'function') {
      return onClick(...args);
    }
  };

  return (
    <a
      role="link"
      target="_blank"
      rel="noreferrer"
      tabIndex={0}
      /* n.b. passed-through props trump defaults, except for events and className */
      {...rest}
      ref={ref}
      className={cn(styles.link, getMarginBottomClass(marginBottom))}
      onClick={onClickWithEventBus}
      onKeyUp={onClickWithEventBus}
    >
      <div>
        <span
          className={cn(
            styles.caption,
            displayStatus && getMarginBottomClass(2)
          )}
        >
          {heading}
        </span>
        {displayStatus && (
          <LinkTileStatus
            opened={actAsControlledComponent ? opened : openedState}
            textMap={textMap}
          />
        )}
      </div>

      <span className={styles.linkIcon}>
        {iconSlot || <IconNewWindow width={'24px'} inline />}
      </span>

      <VisuallyHidden>{textMap.newWindow}</VisuallyHidden>
    </a>
  );
});

LinkTile.displayName = 'LinkTile';

LinkTile.propTypes = {
  /**
   * Indicates whether the Link status is displayed
   */
  displayStatus: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  /**
   * Used for customization of LinkTile icon slot.
   * If not set, uses IconNewWindow by default.
   */
  iconSlot: PropTypes.node,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  /**
   * When set, adds a visual indicator to show that the document was opened.
   * When omitted, `LinkTile` will track whether it's been opened through internal state.
   */
  opened: PropTypes.bool,
  /**
   * Fires on mouse and keyboard events
   */
  onClick: PropTypes.func,
  name: PropTypes.string,
  textMap: PropTypes.shape({
    /**
     * Visually hidden clarifier
     */
    newWindow: PropTypes.string.isRequired,
    /**
     * Indicates that the link was clicked
     */
    opened: PropTypes.string.isRequired,
    /**
     * Indicates that the link is yet to be clicked
     */
    pleaseOpen: PropTypes.string.isRequired,
  }),
};

LinkTile.defaultProps = {
  displayStatus: true,
  marginBottom: 0,
  onClick: () => {},
  name: '',
  textMap: {
    newWindow: 'Opens in a new window',
    opened: 'Opened',
    pleaseOpen: 'Please open',
  },
};

export { LinkTile };

export default LinkTile;
