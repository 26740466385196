import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';

import styles from './paragraph.css';

const Paragraph = ({
  children,
  lead = false,
  hint = false,
  marginBottom = 4,
  small = false,
  ...rest
}) => {
  return (
    <p
      className={cn(
        styles['Paragraph'],
        { [styles.lead]: lead },
        { [styles.small]: small || hint },
        { [styles.hint]: hint },
        getMarginBottomClass(marginBottom)
      )}
      {...rest}
    >
      {children}
    </p>
  );
};

Paragraph.displayName = 'Paragraph';

Paragraph.propTypes = {
  lead: PropTypes.bool,
  small: PropTypes.bool,
  hint: PropTypes.bool,
  children: PropTypes.node.isRequired,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

export default Paragraph;
