import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import cn from 'classnames';
import kebabCase from 'lodash/fp/kebabCase';
import { getMarginBottomClass, marginBottomLevels } from '@piggybank/core';

import { Provider } from './context';
import styles from './field.css';

export default class FieldProvider extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    extraDescriber: PropTypes.string,
    value: PropTypes.any,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    touched: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
      PropTypes.array,
    ]),
    invalid: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    component: PropTypes.oneOf(['div', 'fieldset']),
    children: PropTypes.node,
    marginBottom: PropTypes.oneOf(marginBottomLevels),
  };

  state = {
    describers: [],
  };

  shouldComponentUpdate(nextProps, nextState) {
    return nextState !== this.state || !isEqual(this.props, nextProps);
  }

  register = (datum) => {
    if (!datum || this.state.describers.includes(datum)) {
      return;
    }

    this.setState((state) => ({ describers: state.describers.concat(datum) }));

    return () => {
      this.setState((state) => ({
        describers: state.describers.filter((it) => it !== datum),
      }));
    };
  };

  getDescribers = () => {
    return this.state.describers.join(' ');
  };

  render() {
    const {
      name,
      value,
      onChange,
      onBlur,
      error,
      touched,
      invalid,
      required,
      extraDescriber,
      component: Component = 'div',
      marginBottom = 5,
      children,
      ...rest
    } = this.props;

    return (
      <Component
        className={cn(styles['Field'], getMarginBottomClass(marginBottom))}
        id={kebabCase(`field-${name}`)}
        {...rest}
      >
        <Provider
          value={{
            name,
            value,
            onChange,
            onBlur,
            error,
            touched,
            invalid,
            required,
            describers: cn(this.getDescribers(), extraDescriber) || null,
            register: this.register,
          }}
        >
          {children}
        </Provider>
      </Component>
    );
  }
}
