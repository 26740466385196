import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import kebabCase from 'lodash/fp/kebabCase';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';

import styles from './logo.css';

const Logo = ({ id, textMap = {}, data, marginBottom = 0, ...rest }) => {
  const svgId = id || `${kebabCase(data.title)}-logo`;
  const titleId = `${svgId}-title`;
  const [, , width, height] = data.viewBox.split(' ');

  return (
    <span
      className={cn(styles.LogoWrapper, getMarginBottomClass(marginBottom))}
    >
      <canvas
        className={styles.stretcher}
        width={width}
        height={height}
        aria-hidden
      />
      <svg
        id={svgId}
        className={styles.Logo}
        aria-labelledby={titleId}
        focusable="false"
        role="img"
        version="1.1"
        viewBox={data.viewBox}
        {...rest}
      >
        <title id={titleId}>{textMap.title || data.title}</title>
        {data.svgContent}
      </svg>
    </span>
  );
};

Logo.displayName = 'Logo';

Logo.propTypes = {
  id: PropTypes.string,
  /** docgen-ignore */
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    viewBox: PropTypes.string.isRequired,
    svgContent: PropTypes.node.isRequired,
  }).isRequired,
  textMap: PropTypes.shape({
    title: PropTypes.node,
  }),
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

export { Logo };

export default Logo;
