import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withContext from '../../utils/withContext';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';
import { Consumer } from './context';
import { IconStatusYesThick } from '../Icon';

import styles from './list-item.css';

const ListItem = ({
  type = 'disc',
  marginBottom = 2,
  parentElementType = 'ul',
  columns,
  largeMinWidth,
  children,
  ...rest
}) => (
  <li
    className={cn(
      styles.ListItem,
      parentElementType === 'ol'
        ? styles['OrderedList']
        : styles['UnorderedList'],
      {
        [styles.withIcon]: type === 'tick',
        [styles.columns]: !!columns,
        [styles.largeMinWidth]: largeMinWidth,
      },
      getMarginBottomClass(marginBottom)
    )}
    {...rest}
  >
    {type !== 'none' && (
      <span
        className={cn(styles.listStyle, styles[type])}
        aria-hidden={type === 'tick' ? true : false}
      >
        {type === 'tick' && <IconStatusYesThick inline />}
      </span>
    )}
    <span className={styles.listContent}>{children}</span>
  </li>
);

ListItem.displayName = 'ListItem';

ListItem.propTypes = {
  /** docgen-from-context:<OrderedList/> or <UnorderedList/> */
  type: PropTypes.oneOf([
    'disc',
    'circle',
    'decimal',
    'denary',
    'lower-alpha',
    'lower-roman',
    'none',
    'square',
    'tick',
  ]),
  /** docgen-from-context:<OrderedList/> or <UnorderedList/> */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  children: PropTypes.node,
  /** docgen-ignore */
  parentElementType: PropTypes.oneOf(['ol', 'ul']),
  /** docgen-ignore */
  columns: PropTypes.bool,
  /** docgen-ignore */
  largeMinWidth: PropTypes.bool,
};

export { ListItem };

export default withContext(
  Consumer,
  ({ type, spacing, parentElementType, columns, largeMinWidth }) => ({
    type,
    marginBottom: spacing,
    parentElementType,
    columns,
    largeMinWidth,
  })
)(ListItem);
