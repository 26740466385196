import React from 'react';
import PropTypes from 'prop-types';

import styles from './visually-hidden.css';

const VisuallyHidden = ({
  component: Component = 'span',
  children,
  ...rest
}) => (
  <Component className={styles.VisuallyHidden} {...rest}>
    {children}
  </Component>
);

VisuallyHidden.displayName = 'VisuallyHidden';

VisuallyHidden.propTypes = {
  component: PropTypes.string,
  children: PropTypes.node,
};

export { VisuallyHidden };

export default VisuallyHidden;
