const listeners = new Map();

export const subscribe = (listener) => {
  const key = Symbol();
  listeners.set(key, listener);

  const unsubscribe = () => {
    listeners.delete(key);
  };

  return unsubscribe;
};

export const dispatch = (action) => {
  for (let [, listener] of listeners) {
    listener(action);
  }
};

export default {
  subscribe,
  dispatch,
};
