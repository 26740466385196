import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FieldArray, connect, getIn } from 'formik';
import { TransitionGroup, Transition } from 'react-transition-group';
import omit from 'lodash/fp/omit';

import {
  IconAddThick,
  Link,
  Reveal,
  getMarginBottomClass,
  marginBottomLevels,
  gridStyles,
  eventBus,
  Theme,
} from '@piggybank/core';

import AddAnotherItem from './AddAnotherItem';
import styles from './add-another.css';

class AddAnother extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    /** docgen-from-context:<Form/> */
    formik: PropTypes.object.isRequired,
    showAdd: PropTypes.bool,
    focusFirstInput: PropTypes.bool,
    addInfoSlot: PropTypes.node,
    itemInitialValue: PropTypes.any.isRequired,
    duration: PropTypes.number,
    easing: PropTypes.string,
    /**
     * 0, 1, 2, 3, 4, 5, 6, 7
     */
    marginBottom: PropTypes.oneOf(marginBottomLevels),
    fullWidth: PropTypes.bool,
    textMap: PropTypes.shape({
      addButton: PropTypes.node,
      removeButton: PropTypes.node,
    }),
    renderRemoveButtonText: PropTypes.func,
    renderAddButtonText: PropTypes.func,
    children: PropTypes.func.isRequired,
    minOnMount: PropTypes.number,
    /**
     * proxied to eventBus
     */
    onRemove: PropTypes.func,
    /**
     * proxied to eventBus
     */
    onAdd: PropTypes.func,
    /**
     * This function will control the show / hide of remove button based on index.
     */
    shouldShowRemoveButton: PropTypes.func,
  };

  static defaultProps = {
    min: 0,
    minOnMount: 0,
    max: Infinity,
    showAdd: true,
    focusFirstInput: true,
    duration: 200,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
    marginBottom: 5,
    fullWidth: false,
    textMap: {
      addButton: 'Add another',
      removeButton: 'Remove',
    },
  };

  state = { counter: 0, ids: [] };

  static getDerivedStateFromProps(props, state) {
    // Assumption: New items are added at the end only
    const values = getIn(props.formik.values, props.name);

    const newItemsCount =
      (Array.isArray(values) ? values.length : 0) - state.ids.length;

    if (newItemsCount > 0) {
      const newIds = [];
      let i = 0;

      while (i < newItemsCount) {
        newIds.push(state.counter + i);
        i++;
      }

      return {
        ids: [...state.ids, ...newIds],
        counter: state.counter + i,
      };
    } else if (newItemsCount < 0) {
      return {
        ids: state.ids.slice(0, newItemsCount),
      };
    }

    return null;
  }

  componentDidMount() {
    const { name, minOnMount, itemInitialValue, formik } = this.props;

    const formInitialValues = getIn(formik.values, name) || [];

    if (minOnMount > formInitialValues.length) {
      let i = 0,
        fieldInitialValues = [];

      while (i < minOnMount) {
        fieldInitialValues.push(itemInitialValue);
        i++;
      }

      // https://github.com/jaredpalmer/formik/issues/930
      setTimeout(() => {
        formik.setFieldValue(name, [
          ...formInitialValues,
          ...fieldInitialValues.slice(formInitialValues.length),
        ]);
      }, 0);
    }
  }

  render() {
    const {
      name,
      min,
      max,
      formik, // Supplied by connect() higher-order component from 'formik' package
      itemInitialValue,
      showAdd,
      focusFirstInput,
      addInfoSlot,
      duration,
      easing,
      marginBottom,
      fullWidth,
      textMap,
      renderRemoveButtonText,
      renderAddButtonText,
      children,
      shouldShowRemoveButton,
      ...rest
    } = omit(['onRemove', 'onAdd', 'minOnMount'], this.props);

    const currentValue = getIn(formik.values, name);
    const length = Array.isArray(currentValue) ? currentValue.length : 0;

    const lengthChanged = this.lengthChanged;
    this.lengthChanged = false;

    const strongLink = Theme.select({
      redbrand: false,
      mands: true,
    });

    const checkShowRemoveButton = (index) => {
      return shouldShowRemoveButton
        ? shouldShowRemoveButton(index)
        : length > min;
    };

    return (
      <div
        className={cn(
          {
            [styles.fullWidth]: fullWidth,
            [gridStyles['size-12']]: !fullWidth,
            [gridStyles['sizeMedium-7']]: !fullWidth,
            [gridStyles['sizeLarge-4']]: !fullWidth,
          },
          getMarginBottomClass(marginBottom)
        )}
        {...rest}
      >
        <FieldArray name={name}>
          {(arrayHelpers) => (
            <>
              <TransitionGroup component={null}>
                {this.state.ids.map((id, index) => (
                  <Transition
                    timeout={{
                      enter: 20,
                      exit: duration + 50,
                    }}
                    key={id}
                  >
                    {(state) => (
                      <AddAnotherItem
                        state={state}
                        showRemove={checkShowRemoveButton(id)}
                        onRemove={(event) => {
                          arrayHelpers.remove(index);
                          this.lengthChanged = true;
                          this.setState({
                            ids: this.state.ids.filter((id, i) => i !== index),
                          });

                          eventBus.dispatch({
                            type: 'onRemove',
                            component: 'AddAnother',
                            name,
                            index: index,
                          });

                          if (this.props.onRemove) {
                            this.props.onRemove(event);
                          }
                        }}
                        removeButtonText={
                          renderRemoveButtonText
                            ? renderRemoveButtonText({ index })
                            : textMap.removeButton
                        }
                        focus={
                          lengthChanged && index === this.state.ids.length - 1
                        }
                        focusFirstInput={focusFirstInput}
                        duration={duration}
                        easing={easing}
                        index={index}
                      >
                        {children({
                          path:
                            state !== 'exiting'
                              ? `${name}.${index}`
                              : 'NO_PATH',
                          index,
                        })}
                      </AddAnotherItem>
                    )}
                  </Transition>
                ))}
              </TransitionGroup>
              <Reveal animateOpacity duration={150}>
                {showAdd && length < max && (
                  <>
                    {addInfoSlot && (
                      <div
                        className={
                          cn({
                            [styles.addInfoSlotPadding]: length,
                          }) || null
                        }
                      >
                        {addInfoSlot}
                      </div>
                    )}
                    <Link
                      elementType="button"
                      type="button"
                      strong={strongLink}
                      iconSlotLeft={<IconAddThick inline />}
                      onClick={(event) => {
                        arrayHelpers.push(itemInitialValue);
                        this.lengthChanged = true;

                        eventBus.dispatch({
                          type: 'onAdd',
                          component: 'AddAnother',
                          name: name,
                          index: length,
                        });

                        if (this.props.onAdd) {
                          this.props.onAdd(event);
                        }
                      }}
                      hoverUnderline
                      buttonPadding
                    >
                      {renderAddButtonText
                        ? renderAddButtonText({
                            length,
                          })
                        : textMap.addButton}
                    </Link>
                  </>
                )}
              </Reveal>
            </>
          )}
        </FieldArray>
      </div>
    );
  }
}

export { AddAnother };

const connected = connect(AddAnother);

connected.displayName = 'AddAnother';

export default connected;
