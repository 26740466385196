/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import pick from 'lodash/fp/pick';
import {
  IconChevronDown,
  getMarginBottomClass,
  marginBottomLevels,
  withContext,
  gridStyles,
  eventBus,
} from '@piggybank/core';
import { Consumer } from '../Field/context';

import styles from './select.css';

const shouldRenderPlaceholder = (value, deselectable, options) => {
  const isValueOnTheList = options.some((x) => x.value === value);
  return !value || deselectable || !isValueOnTheList;
};

const renderOption = (option) => (
  <option
    key={option.key || option.value}
    value={option.value}
    className={styles.Suggestion}
  >
    {option.label}
  </option>
);

const getLabelFromValue = (value, options) => {
  const entry = options.find((entry) => entry.value === value);
  return entry && entry.label;
};

const handleChange = (onChange, name) => (event) => {
  const value = event.target.value;
  onChange({ value, event });
  eventBus.dispatch({
    type: 'onChange',
    component: 'Select',
    value,
    name: name,
  });
};

export const Select = React.forwardRef((props, ref) => {
  const {
    fullWidth,
    invalid,
    marginBottom,
    name,
    options,
    placeholder,
    value,
    required,
    describers,
    deselectable,
    onChange,
    ...rest
  } = props;

  const selectedLabel = getLabelFromValue(value, options) || placeholder;

  const isPlaceholderVisible = shouldRenderPlaceholder(
    value,
    deselectable,
    options
  );

  return (
    <div
      className={cn(
        styles.Container,
        {
          [styles.FullWidth]: fullWidth,
          [gridStyles['size-12']]: !fullWidth,
          [gridStyles['sizeMedium-7']]: !fullWidth,
          [gridStyles['sizeLarge-4']]: !fullWidth,
        },
        getMarginBottomClass(marginBottom)
      )}
    >
      <select
        aria-invalid={invalid}
        aria-describedby={describers}
        className={cn(styles.Select, {
          [styles.SelectInvalid]: invalid,
          [styles.Placeholder]: selectedLabel === placeholder,
          // PR with explanation https://alm-github.systems.uk.hsbc/originations/piggybank/pull/307
          [styles.forceWidthChange]: isPlaceholderVisible,
        })}
        name={name}
        id={`${name}-field`}
        onChange={handleChange(onChange, name)}
        required={required}
        ref={ref}
        value={value}
        {...rest}
      >
        {isPlaceholderVisible && (
          <option className={styles.Suggestion} value="">
            {placeholder}
          </option>
        )}
        {options.map(renderOption)}
      </select>
      <div className={styles.Icon}>
        <IconChevronDown marginBottom={0} />
      </div>
    </div>
  );
});

Select.displayName = 'Select';

Select.propTypes = {
  fullWidth: PropTypes.bool,
  /** docgen-from-context:<Field/> */
  invalid: PropTypes.bool,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  /** docgen-from-context:<Field/> */
  name: PropTypes.string.isRequired,
  /**
   * docgen-from-context:<Field/>
   * ---
   * proxied to eventBus
   */
  onChange: PropTypes.func.isRequired,
  /** docgen-from-context:<Field/> */
  onBlur: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      key: PropTypes.string,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  /** docgen-from-context:<Field/> */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** docgen-from-context:<Field/> */
  required: PropTypes.bool,
  /** docgen-from-context:<Field/> */
  describers: PropTypes.string,
  deselectable: PropTypes.bool,
};

Select.defaultProps = { marginBottom: 0, placeholder: 'Please select' };

export default withContext(
  Consumer,
  pick([
    'name',
    'value',
    'onChange',
    'onBlur',
    'invalid',
    'required',
    'describers',
  ])
)(Select);
