import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import omit from 'lodash/fp/omit';
import supportSticky from '../../utils/supportsSticky';
import {
  Button,
  Link,
  IconChevronLeftThick,
  withContext,
  combineEventHandlers,
  getMarginBottomClass,
  marginBottomLevels,
  useTrackingContext,
} from '@piggybank/core';

import { connect } from 'formik';
import { Consumer } from '../Wizard/context';
import styles from './navigation.css';

const Navigation = connect(
  ({
    component: Component = 'nav',
    children,
    currentPage,
    disablePrevious = false,
    formik, // Supplied by connect() higher-order component from 'formik' package
    ownOnNext,
    contextOnNext,
    onPrevious,
    isLastPage,
    marginBottom = 0,
    nextSlot,
    previousSlot,
    textMap = {
      previous: 'Back',
      next: 'Continue',
      submit: 'Submit',
    },
    sticky,
    ...props
  }) => {
    const { isTrackingInUse, track } = useTrackingContext();
    return (
      <Component
        className={cn(
          { [styles['Navigation--fixed']]: sticky && !supportSticky() },
          { [styles['Navigation--sticky']]: sticky && supportSticky() },

          getMarginBottomClass(marginBottom)
        )}
        {...omit(['onNext'], props)}
      >
        <div className={cn({ [styles.wrapper]: sticky })}>
          {sticky && children && (
            <div className={styles.navigationContent}>{children}</div>
          )}
          <div
            className={cn(styles['Navigation'], {
              [styles['Navigation--noBack']]:
                disablePrevious || currentPage === 0,
            })}
          >
            {nextSlot ? (
              nextSlot
            ) : isEmpty(formik) ? (
              <Button
                onClick={combineEventHandlers(ownOnNext, contextOnNext)}
                type="button"
              >
                {isLastPage ? textMap.submit : textMap.next}
              </Button>
            ) : (
              <Button
                onClick={combineEventHandlers(ownOnNext, (e) => {
                  e.preventDefault();
                  if (!formik.isSubmitting) {
                    formik.submitForm();
                  }
                })}
                type="submit"
              >
                {isLastPage ? textMap.submit : textMap.next}
              </Button>
            )}

            {previousSlot ||
              (!disablePrevious && currentPage > 0 && (
                <Link
                  elementType="button"
                  type="button"
                  onClick={() => {
                    isTrackingInUse &&
                      track({
                        type: 'onNavigationBackward',
                        component: 'Navigation',
                      });
                    onPrevious(formik.values);
                  }}
                  iconSlotLeft={
                    <span className={cn(styles.chevronWrapper)}>
                      <IconChevronLeftThick inline />
                    </span>
                  }
                  hoverUnderline
                  buttonPadding
                >
                  <span className={cn(styles.text)}>{textMap.previous}</span>
                </Link>
              ))}
          </div>
        </div>
      </Component>
    );
  }
);

Navigation.displayName = 'Navigation';

const sharedPropTypes = {
  component: PropTypes.string,
  /** docgen-from-context:<Wizard/> */
  disablePrevious: PropTypes.bool,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  nextSlot: PropTypes.node,
  previousSlot: PropTypes.node,
  textMap: PropTypes.shape({
    previous: PropTypes.node,
    next: PropTypes.node,
    submit: PropTypes.node,
  }),
};

Navigation.propTypes = {
  ...sharedPropTypes,
  /** docgen-from-context:<Wizard/> */
  currentPage: PropTypes.number.isRequired,
  /** docgen-ignore */
  ownOnNext: PropTypes.func,
  /** docgen-ignore */
  contextOnNext: PropTypes.func,
  /** docgen-from-context:<Wizard/> */
  onPrevious: PropTypes.func.isRequired,
  /** docgen-from-context:<Wizard/> */
  isLastPage: PropTypes.bool.isRequired,
  sticky: PropTypes.bool,
  children: PropTypes.node,
};

export { Navigation };

const NavigationWithContext = withContext(Consumer, (value, ownProps) => ({
  currentPage: value.currentPage,
  disablePrevious: value.disablePrevious,
  ownOnNext: ownProps.onNext,
  contextOnNext: value.onNext,
  onPrevious: combineEventHandlers(ownProps.onPrevious, value.onPrevious),
  isLastPage: value.isLastPage,
}))(Navigation);

NavigationWithContext.propTypes = {
  ...sharedPropTypes,
  /** docgen-from-context:<Wizard/> */
  onNext: PropTypes.func,
  /** docgen-from-context:<Wizard/> */
  onPrevious: PropTypes.func,
  /** docgen-from-context:<Wizard/> */
  isLastPage: PropTypes.bool,
};

export default NavigationWithContext;
