/* eslint-env browser */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/fp/omit';
import kebabCase from 'lodash/fp/kebabCase';
import AriaModal from 'react-aria-modal';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import eventBus from '../../utils/eventBus';

import Heading from '../Heading';
import { Cell } from '../Grid';
import { IconClose } from '../Icon';
import VisuallyHidden from '../VisuallyHidden';

import styles from './modal-dialog.css';
import pageStyles from '../Page/page.css';

class ModalDialog extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    show: PropTypes.bool,
    drawer: PropTypes.bool,
    alert: PropTypes.bool,
    dismissible: PropTypes.bool,
    getApplicationNode: PropTypes.func,
    component: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    name: PropTypes.string,
    renderOpenButton: PropTypes.func,
    textMap: PropTypes.shape({
      closeButtonLabel: PropTypes.string,
    }),
    /**
     * proxied to eventBus
     */
    onOpen: PropTypes.func,
    /**
     * proxied to eventBus
     */
    onClose: PropTypes.func,
    widthAuto: PropTypes.bool,
  };

  static defaultProps = {
    getApplicationNode: () => document.querySelector(`.${pageStyles.Page}`),
    dismissible: true,
    drawer: false,
    name: '',
    textMap: {
      closeButtonLabel: 'Close dialog',
    },
  };

  state = {
    open: false,
  };

  handleToggle = (event) => {
    const { name } = this.props;
    const controlled = typeof this.props.show !== 'undefined';
    const open = controlled ? this.props.show : this.state.open;

    if (!controlled) this.setState({ open: !this.state.open });

    eventBus.dispatch({
      type: this.state.open ? 'onClose' : 'onOpen',
      component: 'ModalDialog',
      name,
    });

    if (this.props.onOpen && !open) {
      this.props.onOpen(event);
    }
    if (this.props.onClose && open) {
      this.props.onClose(event);
    }
  };

  render() {
    const {
      name,
      title,
      drawer,
      show,
      dismissible,
      getApplicationNode,
      textMap,
      renderOpenButton,
      component: Component,
      children,
      widthAuto,
      ...rest
    } = omit(['onOpen', 'onClose'], this.props);

    const id = `${name.length ? name : kebabCase(title)}-modal-dialog`;
    const titleId = `${id}-title`;

    const controlled = typeof show !== 'undefined';

    const ariaModalProps = {
      dialogClass: drawer ? styles.drawerWrapper : styles.dialogWrapper,
      escapeExits: dismissible,
      initialFocus: `#${drawer ? titleId : id}`,
      focusTrapOptions: {
        escapeDeactivates: dismissible,
      },
      getApplicationNode,
      includeDefaultStyles: false,
      onExit: this.handleToggle,
      titleId,
      underlayClickExits: dismissible,
      underlayClass: styles.underlay,
      ...rest,
    };

    const contentProps = {
      closeDialog: this.handleToggle,
    };

    let content = children;

    if (Component) {
      content = <Component {...contentProps} />;
    }

    if (typeof children === 'function') {
      content = children(contentProps);
    }

    return (
      <>
        {!controlled &&
          renderOpenButton &&
          renderOpenButton({ openDialog: this.handleToggle })}
        <CSSTransition
          in={controlled ? show : this.state.open}
          timeout={{
            enter: 300,
            exit: 200,
          }}
          classNames={{
            enter: styles.enter,
            enterActive: styles.open,
            enterDone: styles.open,
            exit: styles.exit,
          }}
          unmountOnExit
        >
          <AriaModal {...ariaModalProps}>
            <Cell
              sizeMedium={7}
              sizeLarge={drawer ? 6 : 5}
              widthAuto={widthAuto}
            >
              <div
                className={cn({
                  [styles.drawer]: !!drawer,
                  [styles.dialog]: !drawer,
                  [styles.disableBorder]: !!widthAuto,
                })}
                id={id}
                tabIndex={-1}
              >
                <header className={styles.header}>
                  <Heading level={2} id={titleId} marginBottom={0}>
                    {title}
                  </Heading>
                  {dismissible && (
                    <button
                      className={styles.closeButton}
                      type="button"
                      onClick={this.handleToggle}
                    >
                      <IconClose marginBottom={0} />
                      <VisuallyHidden>
                        {textMap.closeButtonLabel}
                      </VisuallyHidden>
                    </button>
                  )}
                </header>
                <section
                  className={cn(styles.content, {
                    [styles.disablePadding]: !!widthAuto,
                  })}
                >
                  {content}
                </section>
              </div>
            </Cell>
          </AriaModal>
        </CSSTransition>
      </>
    );
  }
}

export { ModalDialog };

export default ModalDialog;
