import React, { forwardRef } from 'react';
import assignWith from 'lodash/assignWith';
import isUndefined from 'lodash/fp/isUndefined';
import hoistStatics from 'hoist-non-react-statics';

export const mergeProps = (ownProps, contextProps) =>
  assignWith(
    { ...ownProps },
    contextProps,
    (ownPropValue, contextValue, key) => {
      if (
        !isUndefined(contextValue) &&
        (key.startsWith('on') || isUndefined(ownPropValue)) // @fixme - context provided event handlers have priority over own ones, why?
      ) {
        return contextValue;
      }

      return ownPropValue;
    }
  );

/*
  A higher-order component to connect dumb components to a context <Consumer>.
  For example the Field atom has a context and passes data to its <Provider>,
  then this is used to hook up Label, Input etc. with the matching <Consumer>.
  See https://reactjs.org/docs/context.html.
  The `mapContextToProps` function may be used to pick fields from the data to
  merge with the wrapped component's props.
*/
export default (
  Consumer,
  mapContextToProps = (value) => value,
  mapPropsToRegister = undefined
) => (Component) => {
  // Forward refs.
  // See https://reactjs.org/docs/forwarding-refs.html#forwarding-refs-in-higher-order-components
  const C = forwardRef((props, ref) => (
    <Consumer>
      {(value) => {
        const mergedProps = mergeProps(props, mapContextToProps(value, props));

        if (process.env.NODE_ENV !== 'production' && mapPropsToRegister) {
          // eslint-disable-next-line no-console
          console.warn(
            '"withContext" no longer accepts the third argument. Use "registerAsDescriber" from @piggybank/form to register custom describers.'
          );
        }

        return <Component {...mergedProps} ref={ref} />;
      }}
    </Consumer>
  ));

  // Define a display name for the React devtools.
  // See https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging.
  C.displayName = Component.displayName || Component.name || 'Component';
  C.WrappedComponent = Component;

  return hoistStatics(C, Component, { propTypes: true });
};
