import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';

import styles from './icon.css';

const Icon = ({
  svg: Svg,
  inline = false,
  scaled = false,
  width = '1.125em',
  marginBottom = inline || scaled ? 0 : 2,
  ...rest
}) => {
  return (
    <span
      className={cn(styles.iconWrapper, getMarginBottomClass(marginBottom), {
        [styles['inline']]: inline,
        [styles['scaled']]: scaled,
      })}
    >
      <Svg
        aria-hidden="true"
        className={cn(styles.Icon, { [styles['Icon--scaled']]: scaled })}
        focusable="false"
        role="img"
        version="1.1"
        width={width}
        height={width}
        viewBox="0 0 18 18"
        {...rest}
      />
    </span>
  );
};

Icon.displayName = 'Icon';

Icon.propTypes = {
  /** docgen-ignore */
  svg: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  scaled: PropTypes.bool,
};

export default Icon;
