/* eslint-env browser */
import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';

import styles from './reveal.css';

const getHasChildren = (children) =>
  !!children && React.Children.count(children) > 0;

function contains(parent, child) {
  if (!parent || !child) return false;
  return child.parentNode === parent || contains(parent, child.parentNode);
}

export default class Reveal extends Component {
  static propTypes = {
    accentBar: PropTypes.bool,
    focus: PropTypes.bool,
    duration: PropTypes.number,
    easing: PropTypes.string,
    /**
     * 0, 1, 2, 3, 4, 5, 6, 7
     */
    marginTop: PropTypes.oneOf(marginBottomLevels),
    /**
     * 0, 1, 2, 3, 4, 5, 6, 7
     */
    marginBottom: PropTypes.oneOf(marginBottomLevels),
    children: PropTypes.node,
  };

  static defaultProps = {
    accentBar: false,
    focus: false,
    duration: 200,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
    marginTop: 0,
    marginBottom: 0,
  };

  constructor(props) {
    super(props);

    this.children = props.children;
    this.ref = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.focus &&
      !getHasChildren(prevProps.children) &&
      getHasChildren(this.props.children)
    ) {
      this.ref.current.focus();
    }
    if (
      getHasChildren(prevProps.children) &&
      !getHasChildren(this.props.children) &&
      contains(this.ref.current, document.activeElement)
    ) {
      // if the Reveal is about to collapse, remove focus
      // from children to eliminate the chance of double
      // button presses
      document.activeElement.blur();
    }
  }

  render() {
    const {
      accentBar,
      marginBottom,
      marginTop,
      children,
      focus,
      ...rest
    } = this.props;

    const hasChildren = getHasChildren(children);
    if (hasChildren) this.children = children;

    return (
      <AnimateHeight
        className={getMarginBottomClass(marginBottom)}
        height={hasChildren ? 'auto' : 0}
        aria-hidden={!children}
        onAnimationEnd={() => {
          if (!children) {
            this.children = null;
            this.forceUpdate();
          }
        }}
        {...rest}
      >
        <div
          className={cn(styles['Reveal'], {
            [styles['Reveal--accentBar']]: accentBar,
            [styles['Reveal--collapsing']]: !hasChildren,
          })}
          ref={this.ref}
          tabIndex={focus ? '-1' : null}
        >
          {marginTop > 0 && (
            <div
              className={cn(styles.spacer, getMarginBottomClass(marginTop))}
            />
          )}
          {accentBar ? (
            <div className={styles.accentBar}>{this.children}</div>
          ) : (
            this.children
          )}
        </div>
      </AnimateHeight>
    );
  }
}
