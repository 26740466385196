import React from 'react';
import Theme from '../../../utils/Theme';

const theme = Theme.select({
  blackbrand: 'blackbrand',
  redbrand: 'redbrand',
  mands: 'mands',
});

const SvgComponent = (props) => (
  <>
    {(theme === 'redbrand' || theme === 'mands') && (
      <svg viewBox="0 0 18 18" width="1em" height="1em" {...props}>
        <circle cx={9} cy={9} r={9} />
        <g fill="#fff">
          <circle cx={9.001} cy={5} r={1.25} />
          <path d="M8.1 8.25h1.8v6H8.1z" />
        </g>
      </svg>
    )}

    {theme === 'blackbrand' && (
      <svg viewBox="0 0 18 18" width="1em" height="1em" {...props}>
        <g fill="none" fillRule="evenodd">
          <circle stroke="#2363DA" cx={9} cy={9} r={8.5} />
          <circle fill="#2363DA" cx={9} cy={5} r={1} />
          <line
            x1={9}
            y1={8}
            x2={9}
            y2={13}
            stroke="#2363DA"
            strokeLinecap="round"
          />
        </g>
      </svg>
    )}
  </>
);

export default SvgComponent;
