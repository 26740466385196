import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';
import Theme from '../../utils/Theme';

import styles from './heading.css';

const Heading = forwardRef(
  (
    {
      component,
      accentBar,
      children,
      level,
      marginBottom = level < 4 ? 4 : 2,
      ...rest
    },
    ref
  ) => {
    const Component = component || `h${level}`;
    return (
      <Component
        className={cn(
          styles['Heading'],
          styles[`Heading--${level}`],
          accentBar && Theme.name === 'redbrand'
            ? styles['Heading--accentBar']
            : '',
          getMarginBottomClass(marginBottom)
        )}
        tabIndex="-1"
        ref={ref}
        {...rest}
      >
        {children}
      </Component>
    );
  }
);

Heading.displayName = 'Heading';

Heading.propTypes = {
  accentBar: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.node.isRequired,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

export default Heading;
