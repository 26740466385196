import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './tile.css';
import cn from 'classnames';

const clickableTags = ['input', 'label', 'button', 'a'];

class Tile extends Component {
  constructor(props) {
    super(props);
    this.tile = React.createRef();
  }

  state = {
    focussed: false,
  };

  handleClick = (event) => {
    if (
      this.tile.current.contains(event.target) &&
      !clickableTags.includes(event.target.tagName.toLowerCase()) &&
      !clickableTags.includes(event.target.parentNode.tagName.toLowerCase())
    ) {
      this.props.onChange({ value: !this.props.selected, event });
    }
  };

  handleFocus = (event) => {
    if (event.target.tagName.toLowerCase() === 'input') {
      this.setState({ focussed: true });
    }
  };

  handleBlur = (event) => {
    if (event.target.tagName.toLowerCase() === 'input') {
      this.setState({ focussed: false });
    }
  };

  render() {
    const { children, ...rest } = this.props;
    const { focussed } = this.state;

    return (
      // We choose to disable these errors because this onClick handler has the same functionality as the onChange handler
      // which is attached to the input
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        onClick={this.handleClick}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        className={cn(styles.Tile, {
          [styles.selectedTile]: rest.selected,
          [styles.invalidTile]: rest.invalid,
          [styles.hasFocus]: focussed,
        })}
        ref={this.tile}
      >
        {children({ ...rest, focussed })}
      </div>
    );
  }
}

Tile.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  selected: PropTypes.bool,
  invalid: PropTypes.bool,
  children: PropTypes.func.isRequired,
};

export default Tile;
