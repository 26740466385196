/* eslint-env browser */
import get from 'lodash/fp/get';

const android =
  typeof window !== 'undefined' &&
  typeof document !== 'undefined' &&
  /Android/.test(get('navigator.userAgent', window)) &&
  !window.MSStream;

const iOS =
  typeof window !== 'undefined' &&
  typeof document !== 'undefined' &&
  /iPad|iPhone|iPod/.test(get('navigator.userAgent', window)) &&
  !window.MSStream;

const safari =
  typeof window !== 'undefined' &&
  typeof document !== 'undefined' &&
  !iOS &&
  /^((?!chrome|android).)*safari/i.test(get('navigator.userAgent', window));

const ie11 =
  typeof window !== 'undefined' &&
  typeof document !== 'undefined' &&
  !!window.MSInputMethodContext &&
  !!document.documentMode;

export default {
  iOS,
  safari,
  ie11,
  android,
};
