import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';

import styles from './spinner.css';

const Spinner = ({ marginBottom = 0 }) => (
  <div className={cn(styles.Spinner, getMarginBottomClass(marginBottom))}>
    <div className={styles.spinnerLayer}>
      <div className={cn(styles.circleClipper, styles.left)}>
        <div className={styles.circle} />
      </div>
      <div className={styles.gapPatch}>
        <div className={styles.circle} />
      </div>
      <div className={cn(styles.circleClipper, styles.right)}>
        <div className={styles.circle} />
      </div>
    </div>
  </div>
);

Spinner.displayName = 'Spinner';

Spinner.propTypes = {
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

export { Spinner };

export default Spinner;
