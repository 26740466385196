import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';

import styles from './horizontal-rule.css';

const HorizontalRule = ({
  marginBottom = 4,
  hiddenOnSmallViewport = false,
  ...rest
}) => {
  return (
    <hr
      className={cn(
        styles['HorizontalRule'],
        { [styles['hiddenOnSmallViewport']]: hiddenOnSmallViewport },
        getMarginBottomClass(marginBottom)
      )}
      {...rest}
    />
  );
};

HorizontalRule.propTypes = {
  hiddenOnSmallViewport: PropTypes.bool,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

export default HorizontalRule;
